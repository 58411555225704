import { Button, Tooltip } from "@chakra-ui/react";
import React from "react";
import { hoverButtonStyles } from "../../styles/hover.styles";

interface ButtonIconProps {
  icon: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  tooltipLabel?: string;
}

const ButtonIcon = ({
  icon,
  onClick,
  disabled,
  tooltipLabel,
}: ButtonIconProps) => {
  return (
    <Button
      onClick={onClick}
      isDisabled={disabled}
      bgColor="transparent"
      height="fit-content"
      padding={0}
      _hover={hoverButtonStyles}>
      {tooltipLabel ? (
        <Tooltip label={tooltipLabel}>
          <span>{icon}</span>
        </Tooltip>
      ) : (
        icon
      )}
    </Button>
  );
};

export default ButtonIcon;
