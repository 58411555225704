import { AxiosResponse } from "axios";
import { apiRoutes } from "../constants/api-routes";
import { request } from "../constants/request";
import { Message, MessageWithIncludes } from "../types/Message";

const listMessagesByConversation = async (
  conversationId: string,
  parge: number
): Promise<AxiosResponse<Message[]>> => {
  return request.get(
    apiRoutes.listMessagesByConversation(conversationId, parge)
  );
};

export type SendMessageDto =
  | {
      text: string;
      conversationId?: string;
      recipientPhoneNumberId: string;
      tempId: string;
      file?: File;
    }
  | {
      text: string;
      conversationId: string;
      recipientPhoneNumberId?: string;
      tempId: string;
      file?: File;
    };

const sendMessage = async (
  sendMessageDto: SendMessageDto
): Promise<AxiosResponse<MessageWithIncludes>> => {
  if (sendMessageDto.file) {
    return sendMedia({
      file: sendMessageDto.file!,
      conversationId: sendMessageDto.conversationId!,
      recipientPhoneNumberId: sendMessageDto.recipientPhoneNumberId!,
      tempId: sendMessageDto.tempId,
    });
  }

  return request.post(apiRoutes.sendMessage(), sendMessageDto);
};

export interface SendMessageTemplateDto {
  templateId: string;
  conversationId: string;
  templateArgs?: {
    [key: string]: string | undefined;
  };
}

const sendMessageTemplate = async (
  sendMessageTemplateDto: SendMessageTemplateDto
): Promise<AxiosResponse<MessageWithIncludes>> => {
  return request.post(apiRoutes.sendMessageTemplate(), sendMessageTemplateDto);
};

export interface SendMessageTemplateByPhoneDto {
  templateName: string;
  recipientPhoneNumberId: string;
  recipientName: string;
  templateArgs: Record<string, string | undefined | null>;
}

const sendMessageTemplateByPhone = async (
  sendMessageTemplateByPhoneDto: SendMessageTemplateByPhoneDto
): Promise<AxiosResponse<MessageWithIncludes>> => {
  return request.post(
    apiRoutes.sendMessageTemplateByPhone(),
    sendMessageTemplateByPhoneDto
  );
};

export type DownloadMediaDto =
  | {
      mediaId: string;
      mediaName: string;
      fileKey?: string;
    }
  | {
      mediaId?: string;
      mediaName: string;
      fileKey: string;
    };
const downloadMedia = async (downloadMediaDto: DownloadMediaDto) => {
  return request.get(apiRoutes.downloadMedia(downloadMediaDto), {
    responseType: "blob",
  });
};

export type SendMediaDto =
  | {
      recipientPhoneNumberId: string;
      conversationId?: string;
      file: File;
      tempId: string;
    }
  | {
      conversationId: string;
      recipientPhoneNumberId?: string;
      file: File;
      tempId: string;
    };

const sendMedia = async ({
  file,
  conversationId,
  recipientPhoneNumberId,
  tempId,
}: SendMediaDto): Promise<AxiosResponse<MessageWithIncludes>> => {
  const data = new FormData();

  data.append("file", file);
  if (tempId) {
    data.append("tempId", tempId);
  }
  if (conversationId) {
    data.append("conversationId", conversationId);
  } else {
    data.append("recipientPhoneNumberId", recipientPhoneNumberId!);
  }

  return request.post(apiRoutes.sendMedia(), data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const MessagesService = {
  listMessagesByConversation,
  sendMessage,
  sendMessageTemplate,
  downloadMedia,
  sendMedia,
  sendMessageTemplateByPhone,
};
