import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Select,
  Tag,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { apiRoutes } from "../../../../constants/api-routes";
import { colors } from "../../../../constants/colors";
import { ConversationsService } from "../../../../services/conversations.service";
import ContainerMessages from "../ContainerMessages";
import { IoMdArchive } from "react-icons/io";
import DrawerEditConversation from "../../../../components/DrawerEditConversation";
import { GoBlocked } from "react-icons/go";
import InputArea from "./InputArea";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../state/store";
import {
  isConversationSessionActive,
  isUnreadConversation,
  readConversation,
} from "../../../../state/inboxSlice";
import { useSendTemplateModal } from "../../../../hooks/useSendTemplateModal";
import { FaInfoCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import ButtonIcon from "../../../../components/ButtonIcon";
import { FaPencil } from "react-icons/fa6";
import { ConversationTicketsService, UpdateConversationTicketDto } from "../../../../services/conversation-tickets.service";
import { User } from "../../../../types/Prisma";

interface InboxChatProps {
  toggleListConversations: () => void;
  isDesktop: boolean;
  companyAgents: User[] | undefined;
}

const InboxChat = ({ toggleListConversations, isDesktop, companyAgents }: InboxChatProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const conversationId = searchParams.get("conversationId");
  const { openModal: openSendTemplateModal } = useSendTemplateModal();
  const toast = useToast();
  const agentId = useSelector((state: RootState) =>
    state.inbox.conversations.find((conversation) => conversation.id === conversationId)?.ticketAgentId
  );

  const dispatch = useDispatch<AppDispatch>();
  const isUnread = useSelector((state: RootState) =>
    isUnreadConversation(state, conversationId!)
  );
  const isSessionActive = useSelector((state: RootState) =>
    isConversationSessionActive(state, conversationId!)
  );

  const closeAllConversationTickets = useMutation(
    async () => {
      const { data } = await ConversationsService.closeAllConversationTickets(
        conversationId!
      );
      return data;
    },
    {
      onSuccess: () => {
        toggleListConversations()
        searchParams.delete("conversationId");
        setSearchParams(searchParams);
        toast({
          title: `Conversa movida para "finalizado"`,
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );
  const { data: conversation } = useQuery(
    apiRoutes.showConversation(conversationId!),
    async () => {
      const { data } = await ConversationsService.showConversation(
        conversationId!
      );
      return data;
    },
    {
      enabled: !!conversationId,
      staleTime: 1000 * 5,
    }
  );

  const readConversationMutation = useMutation(
    async () => {
      const { data } = await ConversationsService.readConversation(
        conversationId!
      );
      return data;
    },
    {
      onMutate: () => {
        dispatch(
          readConversation({
            conversationId: conversationId!,
          })
        );
      },
    }
  );

  useEffect(() => {
    if (conversationId) {
      handleReadConversation();
    }
  }, [conversationId]);

  function handleReadConversation() {
    if (isUnread) {
      readConversationMutation.mutate();
    }
  }

  const updateConversationTicket = useMutation(
    async (updateConversationTicketDto: UpdateConversationTicketDto) => {
      return await ConversationTicketsService.updateConversationTicket(
        updateConversationTicketDto
      );
    },
    {
      onSuccess: () => {
        toast({
          title: `Responsável pela conversa atualizado`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  )

  function handleSelectAgent(value: string) {
    const conversationTicket = conversation?.conversationTickets[0];
    if (conversationTicket !== undefined) {
      updateConversationTicket.mutateAsync({
        conversationTicketId: conversationTicket.id,
        agentId: value
      })
    }
  }

  return (
    <Grid
      borderWidth={"1px"}
      borderStyle="solid"
      borderColor={colors.lightGrey}
      height="100%"
      width="100%"
      templateRows="60px 1fr 150px"
      templateColumns="1fr"
      onClick={() => handleReadConversation()}
    >
      <GridItem
        alignItems="center"
        padding={3}
        borderWidth="1px"
        borderStyle="solid"
        borderColor={colors.lightGrey}
        display="grid"
        gridTemplateColumns={isDesktop ? "1fr auto auto" : "auto 1fr auto auto"}
        gap={3}
      >
        {!isDesktop && (
          <ButtonIcon
            icon={<MdKeyboardDoubleArrowLeft fontSize="22px" />}
            onClick={toggleListConversations}
          />
        )}

        <Text
          fontWeight="bold"
          fontSize="lg"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {conversation?.recipientName}
        </Text>

        {conversation?.customer.isOptedOut && (
          isDesktop ? (
            <Tag size="md" colorScheme="red">
              Bloqueado
            </Tag>
          ) : (
              <Box>
                <GoBlocked color="red" fontSize="22px" aria-label="Cliente bloqueado" />
              </Box>
          )
        )}

        { conversationId && (
          <Flex gap={2} alignItems="center">
              {isDesktop && (
                <Box>
                  <Flex alignItems={"center"} gap={2} >
                    <Text fontSize={12}>Responsável:</Text>
                    <Select
                      value={agentId ?? ""}
                      width="fit-content"
                      onChange={(event: any) => {
                        handleSelectAgent(event.target.value);
                      }}
                      size={"sm"}
                      borderRadius={"md"}
                    >
                      {!agentId ||
                        !companyAgents?.find((agent) => agent.id === agentId) ?
                      (
                        <option value="">Ninguém</option>
                      ) : null}
                      {companyAgents?.map((agent: User) => (
                        <option key={agent.id} value={agent.id}>
                          {agent.name}
                        </option>
                      ))
                      }
                    </Select>
                  </Flex>
                </Box>
              )}
              <Box>
                <DrawerEditConversation
                  conversationId={conversationId}
                  trigger={
                    <IconButton
                      aria-label="Editar cliente"
                      paddingLeft="16px"
                      paddingRight="16px"
                      icon={<FaPencil />}
                    />
                  }
                />
              </Box>
          {conversation?.conversationTickets[0]?.status === "open" && (
              <Button
                bgColor="black"
                color="white"
                leftIcon={<IoMdArchive />}
                onClick={() => closeAllConversationTickets.mutate()}
                isLoading={closeAllConversationTickets.isLoading}
                iconSpacing={{ base: 0, md: 2 }}
              >
                {isDesktop ? "Finalizar" : null}
              </Button>
          )}
        </Flex>
        )}
      </GridItem>
      <GridItem overflow={"auto"}>
        {conversationId && (
          <ContainerMessages conversationId={conversationId} />
        )}
      </GridItem>
      <GridItem padding={2} position={"relative"}>
        {isSessionActive ? (
          <InputArea conversation={conversation!} isDesktop={isDesktop} />
        ) : conversationId ? (
          <Box
            bgColor={colors.blueLight}
            padding={3}
            borderRadius={"xl"}
            position={"fixed"}
            bottom={"8px"}
            maxWidth={"95%"}
          >
            <Flex gap={3}>
              <FaInfoCircle fontSize={"18px"} style={{ marginTop: "4px" }} />
              <Box>
                <Text fontWeight="bold">
                  Selecione um template de mensagem do whatsapp
                </Text>
                <Text>
                  Para continuar, você vai precisar enviar um template de
                  mensagem pré-aprovado. Após o cliente responder, você vai
                  poder continuar a conversa
                </Text>
              </Box>
            </Flex>

            <Flex justifyContent={"flex-end"}>
              <Tooltip
                label={
                  conversation?.customer.isOptedOut
                    ? "Desbloqueie o cliente para poder enviar templates"
                    : null
                }
              >
                <Button
                  bgColor="black"
                  color="white"
                  isDisabled={conversation?.customer.isOptedOut}
                  onClick={() =>
                    openSendTemplateModal({
                      conversation: conversation!,
                      templateTypes: [
                        "INITIAL_MESSAGE",
                        "MARKETING",
                        "REVIEW_REQUEST",
                      ],
                      deliveryType: "direct",
                    })
                  }
                >
                  Escolher template
                </Button>
              </Tooltip>
            </Flex>
          </Box>
        ) : null}
      </GridItem>
    </Grid>
  );
};

export default InboxChat;
