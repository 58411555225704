import {
  Table,
  TableContainer,
  Tbody,
  Td, Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import ButtonIcon from "../../../../../components/ButtonIcon";
import { apiRoutes } from "../../../../../constants/api-routes";
import { colors } from "../../../../../constants/colors";
import { useCrudCompanyDefinedFieldModal } from "../../../../../hooks/useCrudCompanyDefinedFieldModal";
import { CompaniesService } from "../../../../../services/companies.service";
import {
  CompanyDefinedFieldDataTypeEnum,
  CompanyDefinedFieldTableEnum
} from "../../../../../types/CompanyDefinedField";
import { FaTrash } from 'react-icons/fa';

const TableColumnSettings = () => {
  const { data: companyDefinedFields } = useQuery(
    apiRoutes.listCompanyDefinedFields(CompanyDefinedFieldTableEnum.CUSTOMERS),
    async () => {
      const { data } = await CompaniesService.listCompanyDefinedFields(
        CompanyDefinedFieldTableEnum.CUSTOMERS
      );
      return data;
    }
  );
  const { handleClickDelete } =
    useCrudCompanyDefinedFieldModal();

  function getTranslatedDataType(
    dataType: CompanyDefinedFieldDataTypeEnum
  ): string {
    const translations: Record<CompanyDefinedFieldDataTypeEnum, string> = {
      string: "TEXTO",
      number: "NÚMERO",
      boolean: "BOOLEANO",
      date: "DATA",
    };

    return translations[dataType] as any;
  }

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Nome do campo</Th>
            <Th>Tipo</Th>
          </Tr>
        </Thead>
        <Tbody>
          {companyDefinedFields?.map((companyDefinedField) => {
            return (
              <Tr key={companyDefinedField.id}>
                <Td fontWeight={"bold"}>{companyDefinedField.name}</Td>
                <Td>{getTranslatedDataType(companyDefinedField.dataType)}</Td>
                <Td
                  display="flex"
                  align="center"
                  justifyContent={"space-around"}>
                  <ButtonIcon
                    icon={<FaTrash fontSize="20px" color={colors.danger} />}
                    onClick={() => handleClickDelete(companyDefinedField.id)}
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default TableColumnSettings;
