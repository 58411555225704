import {
  Box,
  Button,
  DrawerBody,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { FaTrash } from "react-icons/fa";
import * as yup from "yup";
import ButtonIcon from "../../../../../../../components/ButtonIcon";
import { colors } from "../../../../../../../constants/colors";
import { v4 as uuidv4 } from "uuid";
import { ButtonType } from "../../../../../../../types/ButtonTypeEnum";
import { SendWhatsappMessageNodeData } from "../../../../../../../types/ReactFlowNode";
import { useState } from "react";

const buttonSchema = yup
  .object({
    text: yup
      .string()
      .required("Texto é obrigatório")
      .max(20, "Limite máx. de 20 caracteres"),
    index: yup.number().required(),
    url: yup
      .string()
      .url("URL inválida. (Ex: https://www.google.com)")
      .when("type", {
        is: (val: string) => val === ButtonType.URL,
        then: yup
          .string()
          .url("URL inválida. (Ex: https://www.google.com)")
          .required("URL é obrigatória")
          .nullable(false),
        otherwise: yup.string().nullable()
      }),
  })
  .required();

const schema = yup
  .object({
    text: yup.string().required(),
    buttons: yup.array().of(buttonSchema).max(10),
  })
  .required();

export interface SendWhatsappMessageNodeEditorProps {
  data: SendWhatsappMessageNodeData;
  formId: string;
  onSaveNode: (data: any) => void;
}
const SendWhatsappMessageNodeEditor = ({
  data,
  formId,
  onSaveNode,
}: SendWhatsappMessageNodeEditorProps) => {
  const { text, buttons } = data;
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      text,
      buttons:
        buttons?.map((button) => ({
          text: button.text,
          id: button.id,
          type: button.type,
          url: button.url,
        })) || [],
    },
  } as any);
  const {
    fields: buttonTextFields,
    append: appendButtonText,
    remove: removeButtonText,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "buttons", // unique name for your Field Array
  });
  const initialButtonType = buttons?.[0]?.type || null;
  const [buttonType, setButtonType] = useState<ButtonType | null>(initialButtonType);
  const hasQuickReplyButton = getValues("buttons").some(
    (button: { type: ButtonType; }) => button.type === ButtonType.QUICK_REPLY
  );
  const hasUrlButton = getValues("buttons").some(
    (button: { type: ButtonType; }) => button.type === ButtonType.URL
  );

  const handleButtonTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedButtonType = event.target.value as ButtonType;
    setButtonType(selectedButtonType);
    if (!!selectedButtonType) {
      removeButtonText();
      appendButtonText({
        id: uuidv4(),
        type: selectedButtonType,
        text: "",
        isNew: true,
        url: selectedButtonType === ButtonType.URL ? "" : undefined,
      });
    }
  };

  return (
    <DrawerBody>
      <form onSubmit={handleSubmit(onSaveNode)} id={formId}>
        <Flex flexDir={"column"} gap={3}>
          <FormControl>
            <FormLabel>Texto da mensagem</FormLabel>
            <Textarea {...register("text")} />
            <Text color={colors.danger} fontSize="xs">
              {errors.text && <span>{errors.text.message}</span>}
            </Text>
          </FormControl>
          <FormControl>
            <FormLabel>Botões & Links</FormLabel>
            <Tooltip
              label="Para trocar o tipo de botão, remova os botões existentes"
              isDisabled={!hasQuickReplyButton && !hasUrlButton}
            >
              <Box>
                <Select
                  placeholder="Nenhum"
                  value={buttonType || ""}
                  isDisabled={hasQuickReplyButton || hasUrlButton}
                  onChange={handleButtonTypeChange}
                >
                  {[
                    {
                      id: ButtonType.QUICK_REPLY,
                      name: "Resposta rápida",
                    },
                    {
                      id: ButtonType.URL,
                      name: "Abrir URL",
                    },
                  ].map((templateType) => (
                      <option key={templateType.id} value={templateType.id}>
                        {templateType.name}
                      </option>
                    ))}
                </Select>
              </Box>
            </Tooltip>
          </FormControl>


          {(buttonType === ButtonType.QUICK_REPLY ||
            hasQuickReplyButton
           ) ? (
            buttonTextFields.map((field, index) => (
                <Flex display={"flex"} alignItems={"center"} key={field.id}>
                  <FormControl
                    w="500px"
                    key={field.id}
                    display={"flex"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <FormLabel width={"80px"}>Botão {index + 1}</FormLabel>
                    <Box>
                      <Input
                        {...register(`buttons.${index}.text`)}
                        disabled={!("isNew" in buttonTextFields[index])}
                      />
                      <input
                        type="hidden"
                        {...register(`buttons.${index}.index`)}
                        value={index}
                      />
                      <Text color={colors.danger} fontSize="xs">
                        {errors.buttons?.[index]?.text && (
                          <span>{errors.buttons[index].text.message}</span>
                        )}
                      </Text>
                    </Box>
                  </FormControl>
                  {buttonTextFields.length >= 1 && (
                    <ButtonIcon
                      icon={<FaTrash fontSize="20px" color={colors.danger} />}
                      onClick={() => removeButtonText(index)}
                    />
                  )}
                </Flex>
            ))
          ) : null }
          {buttonType === ButtonType.QUICK_REPLY ? (
            buttonTextFields.length < 10 && (
              <Button
                onClick={() => {
                  appendButtonText({
                    id: uuidv4(),
                    type: ButtonType.QUICK_REPLY,
                    text: "",
                    isNew: true,
                  });
                }}
                width={"fit-content"}
              >
                + Adicionar botão
              </Button>
            )
          ) : null}
          {(buttonType === ButtonType.URL &&
            hasUrlButton
          ) ? (
            <Flex display={"flex"} alignItems={"center"}>
              <FormControl
                w="500px"
                display={"flex"}
                alignItems={"center"}
                gap={2}
              >
                <FormLabel width={"80px"}>Botão</FormLabel>
                <Box>
                  <Input
                    {...register(`buttons.0.text`)}
                    placeholder="Título"
                    disabled={!("isNew" in buttonTextFields[0])}
                  />
                  <input
                    type="hidden"
                    {...register(`buttons.0.index`)}
                    value={0}
                  />
                  <Text color={colors.danger} fontSize="xs" position="absolute" mt="2px">
                    {errors.buttons?.[0]?.text && (
                      <span>{errors.buttons[0].text.message}</span>
                    )}
                  </Text>
                </Box>
                <Box>
                  <Input
                    {...register(`buttons.0.url`)}
                    placeholder="URL"
                    disabled={!("isNew" in buttonTextFields[0])}
                  />
                  <input
                    type="hidden"
                    {...register(`buttons.0.index`)}
                    value={0}
                  />
                  <Text color={colors.danger} fontSize="xs" position="absolute" mt="2px">
                    {errors.buttons?.[0]?.url && (
                      <span>{errors.buttons[0].url.message}</span>
                    )}
                  </Text>
                </Box>
              </FormControl>
              {buttonTextFields.length >= 1 && (
                <ButtonIcon
                  icon={<FaTrash fontSize="20px" color={colors.danger} />}
                  onClick={() => removeButtonText(0)}
                />
              )}
            </Flex>
          ) : null}
          {(buttonType === ButtonType.URL &&
            !hasUrlButton
          ) &&
           buttonTextFields.length < 1 && (
            <Button
              onClick={() => {
                appendButtonText({
                  id: uuidv4(),
                  type: ButtonType.URL,
                  text: "",
                  url: "",
                  isNew: true,
                });
              }}
              width={"fit-content"}
            >
              + Adicionar botão
            </Button>
          )}
        </Flex>
      </form>
    </DrawerBody>
  );
};

export default SendWhatsappMessageNodeEditor;
