import { FormControl } from "@chakra-ui/react";
import { useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { AiOutlineTags } from "react-icons/ai";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import FormLabel from "../../../../../components/FormLabel";
import InputSelect from "../../../../../components/InputSelect";
import { apiRoutes } from "../../../../../constants/api-routes";
import { useCustomerSearchParams } from "../../../../../hooks/useCustomerSearchParams";
import { TagsService } from "../../../../../services/tags.service";
import { RootState } from "../../../../../state/store";
import { Tag } from "../../../../../types/Tag";
import AccordionItemLayout from "../AccordionItemLayout";

interface SectionTagsProps {
  tags?: Tag[];
  useFormReturn: UseFormReturn<any>;
  updateSelectedValues: (args: any) => void;
}

const SectionTags = ({
  useFormReturn: { control },
  updateSelectedValues,
}: SectionTagsProps) => {
  const { refetchKey } = useSelector((state: RootState) => state.campaignCreation);
  const { selectedTags, excludedTags } = useCustomerSearchParams();
  const { data: tags, refetch: refetchTags } = useQuery(
    apiRoutes.listTags(),
    async () => {
      const { data } = await TagsService.listTags();
      return data;
    }
  );

  useEffect(() => {
    refetchTags();
  }, [refetchKey, refetchTags]);

  useEffect(() => {
    updateSelectedValues({
      selectedValues: selectedTags,
      sourceData: tags,
      valueToSet: "selectedTags",
      optionValue: "id",
      optionLabel: "name",
    });

    updateSelectedValues({
      selectedValues: excludedTags,
      sourceData: tags,
      valueToSet: "excludedTags",
      optionValue: "id",
      optionLabel: "name",
    });
  }, [excludedTags, selectedTags, tags, updateSelectedValues]);

  return (
    <AccordionItemLayout title="Tags" icon={<AiOutlineTags size="18px" />}>
      <FormControl>
        <FormLabel
          size="sm"
          tooltip="Mostrar clientes com pelo menos uma das tags selecionadas">
          Mostrar tags
        </FormLabel>
        <Controller
          name="selectedTags"
          control={control}
          render={({ field }) => (
            <InputSelect
              placeholder="Selecionar tags"
              options={(tags || []).map((tag) => ({
                value: tag.id,
                label: tag.name,
              }))}
              isMulti
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
              }}
            />
          )}
        />
      </FormControl>
      <FormControl>
        <FormLabel
          size="sm"
          tooltip="Ocultar clientes com pelo menos uma das tags selecionadas">
          Ocultar tags
        </FormLabel>
        <Controller
          name="excludedTags"
          control={control}
          render={({ field }) => (
            <InputSelect
              placeholder="Selecionar tags"
              options={(tags || []).map((tag) => ({
                value: tag.id,
                label: tag.name,
              }))}
              isMulti
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
              }}
            />
          )}
        />
      </FormControl>
    </AccordionItemLayout>
  );
};

export default SectionTags;
