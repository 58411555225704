import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MdOutlineCampaign } from "react-icons/md";
import { RiNodeTree } from "react-icons/ri";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CardStatistic from "../../components/CardStatistic";
import CustomECharts from "../../components/CustomECharts";
import RFMChart from "../../components/RFMChart";
import { apiRoutes } from "../../constants/api-routes";
import { appPaths } from "../../constants/app-paths";
import { colors } from "../../constants/colors";
import { RecommendationsService } from "../../services/recommendations.service";
import { StatisticsService } from "../../services/statistics.service";
import { RootState } from "../../state/store";
import { Recommendation } from "../../types/Recommendation";
import { MoneyUtils } from "../../utils/money.utils";

const recommendationIconByType = {
  create_automation: <RiNodeTree size="24px" />,
  create_campaign: <MdOutlineCampaign size="24px" />,
};

const ctaByType = {
  create_automation: "Criar Automação",
  create_campaign: "Criar Campanha",
};

const colorByWeekDay: Record<string, string> = {
  sunday: "#A3C1E9",
  monday: "#FFB07C",
  tuesday: "#98D6A5",
  wednesday: "#FF9999",
  thursday: "#C1A4E9",
  friday: "#A0E6EF",
  saturday: "#E5E39A",
};

const HomePage = () => {
  const { currentUser } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: revenueSummary, isFetching: isFetchingRevenueSummary } =
    useQuery(
      apiRoutes.getReviRevenueSummary(),
      async () => {
        const { data } = await StatisticsService.getReviRevenueSummary();
        return data;
      },
      {
        staleTime: 1000 * 60 * 5,
      }
    );

  const {
    data: customersKpiSummary,
    isFetching: isFetchingCustomersKpiSummary,
  } = useQuery(
    apiRoutes.getCustomersKpiSummary(),
    async () => {
      const { data } = await StatisticsService.getCustomersKpiSummary();
      return data;
    },
    {
      staleTime: 1000 * 60 * 5,
    }
  );

  const { data: recommendations, isFetching: isFetchingRecommendations } =
    useQuery(apiRoutes.listCompanyRecommendations(), async () => {
      const { data } =
        await RecommendationsService.listCompanyRecommendations();
      return data;
    });

  const { data: ordersCountByWeekDayAndHour } = useQuery(
    apiRoutes.getOrdersCountByWeekDayAndHour(),
    async () => {
      const { data } = await StatisticsService.getOrdersCountByWeekDayAndHour();
      return data;
    }
  );

  const ordersByWeekDayAndHour = (ordersCountByWeekDayAndHour || []).map(
    (item: any) => [String(item.hour), item.weekDay, item.count]
  );
  const maxOrderCountByWeekdayAndHour = (
    ordersCountByWeekDayAndHour || []
  ).reduce((acc: number, item: any) => Math.max(acc, item.count), 1);

  function handleClickRecommendation(recommendation: Recommendation) {
    if (recommendation.type === "create_campaign") {
      navigate(
        `${appPaths.campaigns.create()}?${recommendation.data.filterCriteria}`
      );
    } else if (recommendation.type === "create_automation") {
      navigate(appPaths.automations.backgroundAutomations.createAutomation());
    }
  }

  function normalizeValue(value: number, maxValue: number = 50): number {
    return (value / maxOrderCountByWeekdayAndHour) * maxValue;
  }

  return (
    <Grid
      gap={2}
      templateAreas={`
        "header header header"
        "revenue-stats revenue-stats revenue-stats"
        "rfm-chart rfm-chart recommendations"
        "orders-count-chart orders-count-chart orders-count-chart"
      `}
      gridTemplateRows={"auto auto 1fr auto"}
      gridTemplateColumns={"1fr 1fr 1fr"}>
      <GridItem
        area="header"
        alignItems={"center"}
        bgColor={"#8BD9F7"}
        display="flex"
        gap={4}>
        <Image
          src="/logo512.png"
          width="150px"
          height="150px"
          objectFit={"cover"}
        />
        <Flex alignItems={"center"} justifyContent="space-between" flex={1}>
          <Flex flexDir="column" alignItems={"center"}>
            <Heading textTransform={'uppercase'}>hey {currentUser?.name?.split(' ')[0] || ''}</Heading>
            <Text>Gosta de números? Nós também!</Text>
          </Flex>
          <Flex flexDir="column" gap={2}>
            <Text fontWeight={"bold"}>Não sabe por onde começar?</Text>
            <Button
              mr={4}
              bgColor={colors.primary}
              color={colors.white}
              as="a"
              href="https://wa.me/+5511935025879?text=Olá Juliana, gostaria de ajuda com estratégias na Revi"
              target={"_blank"}>
              Falar com um especialista
            </Button>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem area="revenue-stats" display="flex">
        <Card flex={1}>
          <CardHeader>
            <Heading size="md">Receita influenciada pela Revi</Heading>
          </CardHeader>
          <CardBody display="flex" gap={4}>
            {[
              {
                title: "Total",
                value: MoneyUtils.formatCurrency(revenueSummary?.total || 0, 0),
                icon: "R$",
                tooltip: "Receita total influenciada pela Revi",
              },
              {
                title: "Marketing",
                value: MoneyUtils.formatCurrency(
                  revenueSummary?.marketing || 0,
                  0
                ),
                icon: <MdOutlineCampaign size="24px" />,
                tooltip:
                  "Receita influenciada por campanhas de marketing da Revi",
              },
              {
                title: "Automações",
                value: MoneyUtils.formatCurrency(
                  revenueSummary?.automation || 0,
                  0
                ),
                icon: <RiNodeTree size="24px" />,
                tooltip: "Receita influenciada por automações da Revi",
              },
            ].map((stats) => (
              <CardStatistic
                icon={stats.icon}
                title={stats.title}
                value={stats.value}
                key={stats.title}
                size="sm"
                tooltip={stats.tooltip}
                isLoading={isFetchingRevenueSummary}
              />
            ))}
          </CardBody>
        </Card>
        <Card flex={1}>
          <CardHeader>
            <Heading size="md">KPIs</Heading>
          </CardHeader>
          <CardBody display="flex" gap={4}>
            {[
              {
                title: "Ticket médio",
                value: MoneyUtils.formatCurrency(
                  customersKpiSummary?.avgOrderValuePerCustomer || 0,
                  0
                ),
                icon: "R$",
                tooltip: "Ticket médio por cliente",
              },
              {
                title: "LTV",
                value: MoneyUtils.formatCurrency(
                  customersKpiSummary?.avgTotalOrdersValuePerCustomer || 0,
                  0
                ),
                icon: "LTV",
                tooltip: "Total gasto por cliente",
              },
              {
                title: "Frequência",
                value: `${(
                  customersKpiSummary?.avgTotalOrdersPerCustomer || 0
                ).toFixed(1)}x`,
                icon: "Freq",
                tooltip: "Frequência de compras por cliente",
              },
            ].map((stats) => (
              <CardStatistic
                icon={stats.icon}
                title={stats.title}
                value={stats.value}
                key={stats.title}
                size="sm"
                tooltip={stats.tooltip}
                isLoading={isFetchingCustomersKpiSummary}
              />
            ))}
          </CardBody>
        </Card>
      </GridItem>
      <GridItem area="rfm-chart">
        <Card>
          <CardHeader>
            <Heading size="md">Sua matriz RFM</Heading>
          </CardHeader>
          <CardBody>
            <RFMChart />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem area="recommendations" display="flex" flexDir="column" gap={4}>
        <Card height="100%">
          <CardHeader>
            <Heading size="md">Nossas recomendações</Heading>
          </CardHeader>
          <CardBody display="flex" flexDir={"column"}>
            <Box maxHeight="400px" overflow="scroll">
              {isFetchingRecommendations ? (
                <Center height="100%">
                  <Spinner />
                </Center>
              ) : (
                (recommendations || []).map((recommendation, index) => (
                  <Card
                    direction={{ base: "column", md: "row" }}
                    variant="outline"
                    key={index}
                    borderRadius="md"
                    overflow="hidden"
                    boxShadow="md"
                    mb={4}
                    p={4}
                    _hover={{ boxShadow: "lg" }}>
                    <Center
                      marginRight={{ base: 0, md: "20px" }}
                      marginBottom={{ base: "10px", md: 0 }}
                      color="white"
                      borderRadius="50%"
                      bgColor={colors.primary}
                      height="40px"
                      width="40px"
                      fontSize="16px"
                      aria-hidden="true">
                      {recommendationIconByType[recommendation.type]}
                    </Center>
                    <Stack spacing={3} flex="1">
                      <CardBody p={0}>
                        <Text
                          fontSize="20px"
                          dangerouslySetInnerHTML={{
                            __html: recommendation.title,
                          }}
                        />
                        <Text py={2} color="gray.600">
                          {recommendation.description}
                        </Text>
                      </CardBody>
                      <CardFooter p={0} pt={2}>
                        <Button
                          variant="solid"
                          bgColor={colors.primary}
                          color={colors.white}
                          width={{ base: "100%", md: "auto" }}
                          aria-label={recommendation.title}
                          onClick={() =>
                            handleClickRecommendation(recommendation)
                          }>
                          {ctaByType[recommendation.type]}
                        </Button>
                      </CardFooter>
                    </Stack>
                  </Card>
                ))
              )}
            </Box>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem area="orders-count-chart">
        <Card>
          <CardHeader>
            <Heading size="md">Seus horários com mais pedidos</Heading>
          </CardHeader>
          <CardBody>
            <CustomECharts
              option={{
                legend: {},
                tooltip: {
                  position: "top",
                  formatter: (params: any) => {
                    const [hour, weekDay, count] = params.value;
                    return `<strong>${t(
                      `weekdays.long.${weekDay}`
                    )} às ${hour}:00</strong> - ${count} pedidos`;
                  },
                },
                xAxis: {
                  type: "category",
                  data: [
                    ...new Set(
                      (ordersCountByWeekDayAndHour || []).map(
                        (item: any) => item.hour
                      )
                    ),
                  ],
                  splitLine: {
                    show: true,
                  },
                  axisLine: {
                    show: false,
                  },
                  boundaryGap: false,
                },
                yAxis: {
                  type: "category",
                  axisLabel: {
                    formatter: (value: string) => t(`weekdays.long.${value}`),
                  },
                  data: [
                    "saturday",
                    "friday",
                    "thursday",
                    "wednesday",
                    "tuesday",
                    "monday",
                    "sunday",
                  ],
                  splitLine: {
                    show: true,
                  },
                  axisLine: {
                    show: false,
                  },
                  boundaryGap: false,
                },
                series: [
                  {
                    name: "Total de pedidos",
                    type: "scatter",
                    data: ordersByWeekDayAndHour,
                    itemStyle: {
                      color: function (curr: any) {
                        return colorByWeekDay[curr.value[1] as string];
                      },
                    },
                    symbolSize: function (params: any) {
                      return normalizeValue(params[2]);
                    },
                    animationDelay: function (idx: any) {
                      return idx * 5;
                    },
                  },
                ],
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </Grid>
  );
};

export default HomePage;
