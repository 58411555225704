import { useQuery } from "react-query";
import CardStatistic, {
  CardStatisticProp
} from "../CardStatistic";
import { request } from "../../constants/request";

interface CardStatisticContainerProps extends Omit<CardStatisticProp, "value"> {
  requestRoute: string;
  valueFormatter?: (value: string | number) => string;
}

const CardStatisticContainer = ({
  icon,
  requestRoute,
  title,
  valueFormatter,
  bgIconColor,
  tooltip,
}: CardStatisticContainerProps) => {
  const { data, isFetching } = useQuery(
    requestRoute,
    async () => {
      const res = await request.get(requestRoute);
      return res.data;
    },
    {}
  );

  return (
    <CardStatistic
      icon={icon}
      title={title}
      value={valueFormatter ? valueFormatter(data) : data}
      isLoading={isFetching}
      bgIconColor={bgIconColor}
      tooltip={tooltip}
    />
  );
};

export default CardStatisticContainer;
