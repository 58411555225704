import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Divider, Flex, Image, Link, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import reactStringReplace from "react-string-replace";
import { colors } from "../../constants/colors";
import { Button } from "../../services/message-templates.service";
import { ButtonType } from "../../types/ButtonTypeEnum";
import { FileUtils } from "../../utils/file-utils";

export interface WhatsappMessageItemProps {
  message?: string | null;
  file?: File | null;
  buttons?: Button[];
  footer?: string | null;
  fileUrl?: string | null;
}

const whatsappImageWidth = 1125 / 4;
const WhatsappMessageItem = ({
  message,
  file,
  buttons,
  footer,
  fileUrl,
}: WhatsappMessageItemProps) => {
  const [filePreview, setFilePreview] = useState<undefined | null | File>(null);
  const buttonType = buttons?.[0]?.type || null;

  useEffect(() => {
    if (fileUrl) {
      FileUtils.fetchFile(fileUrl).then((file) => {
        setFilePreview(file);
      });
    }
  }, [fileUrl]);

  useEffect(() => {
    if (file) {
      setFilePreview(file);
    }
  }, [file]);

  useEffect(() => {
    setFilePreview(null);
  }, [fileUrl]);

  return (
    <Box width={whatsappImageWidth + 8} maxWidth={"100%"} fontSize={"15px"}>
      <Box
        bgColor={"#FFFFFF"}
        padding={1}
        borderTopRadius="5px"
        borderBottomRadius={(buttons?.length || 0) === 0 ? "5px" : "0px"}>
        {!filePreview ? null : filePreview.type.includes("pdf") ? (
          <Flex
            alignItems={"center"}
            bgColor={"#F5F6F6"}
            paddingX={4}
            paddingY={3}
            fontSize={12}
            gap={2}
            borderRadius="5px">
            <Image src="/icons/pdf.png" width={"26px"} height={"30px"} />
            {filePreview.name}
          </Flex>
        ) : filePreview.type.startsWith("video") ? (
          <video
            style={{ maxWidth: "100%", maxHeight: "100%", borderRadius: "5px" }}
            controls>
            <source
              src={URL.createObjectURL(filePreview)}
              type={filePreview.type}
            />
          </video>
        ) : (
          <Image
            src={URL.createObjectURL(filePreview)}
            alt={filePreview.name}
            width={"100%"}
            mb={2}
            borderRadius="5px"
            objectFit={"contain"}
          />
        )}
        <Box padding={1}>
          <Text whiteSpace={"pre-line"}>
            {reactStringReplace(
              message || undefined,
              /\*(.*?)\*/g,
              (match, i) => (
                <Text as="span" fontWeight={"bold"} key={i}>
                  {match}
                </Text>
              )
            )}
          </Text>

          <Text fontSize={13} marginTop={2} color={"#8696a0"}>
            {footer}
          </Text>
        </Box>
      </Box>
      {buttonType === ButtonType.URL ? (
        <Box bgColor={"#FFFFFF"} borderBottomRadius="5px">
          <Divider />
          <Flex
            flexDir="column"
            justifyContent={"center"}
            alignItems="center"
            padding={2}>
            <Link color={colors.hyperlink}>
              <ExternalLinkIcon mx="3px" color={colors.hyperlink} />
              {buttons![0].text}
            </Link>
          </Flex>
        </Box>
      ) : buttonType === ButtonType.QUICK_REPLY ? (
        <Flex flexWrap={"wrap"} width="100%" gap={1} marginTop={1}>
          {buttons?.map((button, index) => (
            <Flex
              bgColor={"#FFFFFF"}
              flex={1}
              padding={2}
              borderRadius={"5px"}
              minWidth="48%"
              justifyContent={"center"}
              alignItems="center"
              color={"#009de2"}>
              {button.text}
            </Flex>
          ))}
        </Flex>
      ) : null}
    </Box>
  );
};

export default WhatsappMessageItem;
