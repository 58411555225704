import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { appPaths } from "../constants/app-paths";
import { SendTemplateModalProvider } from "../hooks/useSendTemplateModal";
import AppLayout from "../layouts/AppLayout";
import AutomaticSortingPage from "../pages/AutomationsPage/AutoSortingPage";
import CustomersPage from "../pages/CustomersPage";
import WhatsappCampaignsPage from "../pages/CampaignsPage/WhatsappCampaignsPage";
import MessageTemplatesPage from "../pages/MessageTemplatePage";
import SettingsPage from "../pages/SettingsPage/GeneralSettingsPage";
import { ReactFlowProvider } from "reactflow";
import { CrudAutoReplyModalProvider } from "../hooks/useCrudAutoReplyModal";
import { CrudCompanyDefinedFieldModalProvider } from "../hooks/useCrudCompanyDefinedFieldModal";
import AutomationsLayout from "../layouts/AutomationsLayout";
import SettingsLayout from "../layouts/SettingsLayout";
import AutoRepliesPage from "../pages/AutomationsPage/AutoRepliesPage";
import BackgroundAutomationsPage from "../pages/AutomationsPage/BackgroundAutomationsPage";
import BackgroundAutomationDetailsPage from "../pages/AutomationsPage/BackgroundAutomationsPage/BackgroundAutomationDetailsPage";
import CreateBackgroundAutomationPage from "../pages/AutomationsPage/BackgroundAutomationsPage/CreateBackgroundAutomationPage";
import MessagesFlowPage from "../pages/AutomationsPage/MessageFlowsPage";
import EditMessageFlowPage from "../pages/AutomationsPage/MessageFlowsPage/EditMessageFlowPage";
import SmsCampaignsPage from "../pages/CampaignsPage/SmsCampaignsPage";
import WhatsappCampaignDetailsPage from "../pages/CampaignsPage/WhatsappCampaignsPage/WhatsappCampaignDetailsPage";
import CreateMessageTemplatePage from "../pages/MessageTemplatePage/CreateMessageTemplatePage";
import AccountSettingsPage from "../pages/SettingsPage/AccountSettingsPage";
import ColumnSettingsPage from "../pages/SettingsPage/ColumnSettingsPage";
import IntegrationSettingsPage from "../pages/SettingsPage/IntegrationSettingsPage";
import { MixpanelService } from "../services/mixpanel.service";
import InboxPage from "../pages/InboxPage";
import TagSettingsPage from "../pages/SettingsPage/TagSettingsPage";
import CreateCampaignPage from "../pages/CampaignsPage/CreateCampaignPage";
import QuickRepliesPage from "../pages/SettingsPage/QuickRepliesPage";
import { CrudQuickReplyModalProvider } from "../hooks/useCrudQuickReplyModal";
import { CrudTagModalProvider } from "../hooks/useCrudTagModal";
import ReportsLayout from "../pages/ReportsPage/ReportsLayout";
import ReportsOverviewPage from "../pages/ReportsPage/OverviewPage";
import HomePage from "../pages/HomePage";
import { useMediaQuery } from "@chakra-ui/react";
import { MAX_MOBILE_SCREEN_WIDTH, screenSizes } from "../constants/screen-sizes";

const ConversationsElement = () => (
  <SendTemplateModalProvider>
    <InboxPage />
  </SendTemplateModalProvider>
);

const routes = [
  {
    path: appPaths.conversations(),
    element: <ConversationsElement />,
  },
  {
    path: appPaths.home(),
    element: (
        <HomePage />
    ),
  },
  {
    path: appPaths.automations.messageFlows.editMessageFlow(":flowId"),
    element: (
      <ReactFlowProvider>
        <EditMessageFlowPage />
      </ReactFlowProvider>
    ),
  },
  {
    path: appPaths.automations.messageFlows.index(),
    element: (
      <AutomationsLayout>
        <MessagesFlowPage />
      </AutomationsLayout>
    ),
  },
  {
    path: appPaths.automations.autoSorting(),
    element: (
      <AutomationsLayout>
        <AutomaticSortingPage />
      </AutomationsLayout>
    ),
  },
  {
    path: appPaths.automations.autoReplies(),
    element: (
      <AutomationsLayout>
        <CrudAutoReplyModalProvider>
          <AutoRepliesPage />
        </CrudAutoReplyModalProvider>
      </AutomationsLayout>
    ),
  },
  {
    path: appPaths.automations.backgroundAutomations.index(),
    element: (
      <AutomationsLayout>
        <CrudAutoReplyModalProvider>
          <BackgroundAutomationsPage />
        </CrudAutoReplyModalProvider>
      </AutomationsLayout>
    ),
  },
  {
    path: appPaths.automations.backgroundAutomations.createAutomation(),
    element: (
      <AutomationsLayout>
        <CrudAutoReplyModalProvider>
          <CreateBackgroundAutomationPage />
        </CrudAutoReplyModalProvider>
      </AutomationsLayout>
    ),
  },
  {
    path: appPaths.automations.backgroundAutomations.details(":automationId"),
    element: <BackgroundAutomationDetailsPage />,
  },
  {
    path: appPaths.settings.general(),
    element: (
      <SettingsLayout>
        <SettingsPage />
      </SettingsLayout>
    ),
  },
  {
    path: appPaths.settings.customColumns(),
    element: (
      <SettingsLayout>
        <CrudCompanyDefinedFieldModalProvider>
          <ColumnSettingsPage />
        </CrudCompanyDefinedFieldModalProvider>
      </SettingsLayout>
    ),
  },
  {
    path: appPaths.settings.tags(),
    element: (
      <SettingsLayout>
        <CrudTagModalProvider>
          <TagSettingsPage />
        </CrudTagModalProvider>
      </SettingsLayout>
    ),
  },
  {
    path: appPaths.settings.accountSettings(),
    element: (
      <SettingsLayout>
        <AccountSettingsPage />
      </SettingsLayout>
    ),
  },
  {
    path: appPaths.settings.integrationSettings(),
    element: (
      <SettingsLayout>
        <IntegrationSettingsPage />
      </SettingsLayout>
    ),
  },
  {
    path: appPaths.settings.quickReplies(),
    element: (
      <SettingsLayout>
        <CrudQuickReplyModalProvider>
          <QuickRepliesPage />
        </CrudQuickReplyModalProvider>
      </SettingsLayout>
    ),
  },
  {
    path: appPaths.customers.index(),
    element: (
      <SendTemplateModalProvider>
        <CustomersPage />
      </SendTemplateModalProvider>
    ),
  },
  {
    path: appPaths.messageTemplates.index(),
    element: <MessageTemplatesPage />,
  },
  {
    path: appPaths.messageTemplates.createMessageTemplate(),
    element: <CreateMessageTemplatePage />,
  },
  {
    path: appPaths.campaigns.create(),
    element: <CreateCampaignPage />,
  },
  {
    path: appPaths.campaigns.sms.index(),
    element: <SmsCampaignsPage />,
  },
  {
    path: appPaths.campaigns.whatsapp.index(),
    element: <WhatsappCampaignsPage />,
  },
  {
    path: appPaths.campaigns.whatsapp.details(":campaignId"),
    element: <WhatsappCampaignDetailsPage />,
  },
  {
    path: appPaths.reports.index(),
    element: (
      <ReportsLayout>
        <ReportsOverviewPage />
      </ReportsLayout>
    ),
  },
];

const AppRoutes = () => {
  const location = useLocation();
  const [isMobile] = useMediaQuery(screenSizes.mobile);

  useEffect(() => {
    MixpanelService.track("page-view", { path: location.pathname });
  }, [location.pathname]);

  if (window.screen.width < MAX_MOBILE_SCREEN_WIDTH) {
    return <ConversationsElement />;
  }

  return (
    <Routes>
      {routes.map(({ path, element }) => (
        <Route
          key={path}
          path={path}
          element={<AppLayout>{element}</AppLayout>}
        />
      ))}
      <Route
        path={"/*"}
        element={<Navigate to={isMobile ? appPaths.conversations() : appPaths.home()} replace />}
      />
    </Routes>
  );
};

export default AppRoutes;
