import {
  Box,
  Button,
  Divider,
  Flex, Heading, Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Radio,
  RadioGroup, Stack, Text, useBoolean, useToast
} from "@chakra-ui/react";
import { startOfDay, startOfMonth, startOfWeek, subDays } from "date-fns";
import { useState } from "react";
import { BiConversation, BiSolidMessageX } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa";
import { MdCampaign } from "react-icons/md";
import { PiArrowsClockwiseBold } from "react-icons/pi";
import { TbTableExport } from "react-icons/tb";
import { apiRoutes } from "../../../constants/api-routes";
import { colors } from "../../../constants/colors";
import useDownloadFile from "../../../hooks/useDownloadFile";
import { ReportsService } from "../../../services/reports.service";
import CardStatisticContainer from "../../../components/CardStatisticContainer";
import Header from "../components/Header";
import TableWhatsappCampaigns from "./components/TableWhatsappCampaigns";

const ButtonExportCampaignResults = () => {
  const last7Days = startOfDay(subDays(new Date(), 7)).toISOString();
  const [startDate, setStartDate] = useState(last7Days);
  const { downloadExcelReport } = useDownloadFile();
  const [isExporting, setIsExporting] = useBoolean(false);
  const toast = useToast();

  async function handleClickExportCampaigns() {
    setIsExporting.on();
    try {
      const { data } = await ReportsService.exportCampaignSalesReport(
        startDate,
        new Date().toISOString()
      );
      downloadExcelReport(data, "resultado-de-campanhas.xlsx");
      toast({
        title: "Exportação realizada com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }  finally {
      setIsExporting.off();
    }
  }

  const options = [
    {
      label: "Últimos 7 dias",
      value: last7Days,
    },
    {
      label: "Últimos 30 dias",
      value: startOfDay(subDays(new Date(), 30)).toISOString(),
    },
    {
      label: "Semana Atual",
      value: startOfWeek(new Date()).toISOString(),
    },
    {
      label: "Semana Anterior",
      value: startOfWeek(subDays(new Date(), 7)).toISOString(),
    },
    {
      label: "Mês Atual",
      value: startOfMonth(new Date()).toISOString(),
    },
    {
      label: "Mês Anterior",
      value: startOfMonth(subDays(startOfMonth(new Date()), 1)).toISOString(),
    },
  ];

  return (
    <Popover>
      <PopoverTrigger>
        <Button bgColor={colors.primary} color={colors.white} leftIcon={<TbTableExport size={20} />}>Exportar</Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Selecione o período</PopoverHeader>
        <PopoverBody>
          <RadioGroup onChange={setStartDate} value={startDate}>
            <Stack direction="column">
              {options.map(({ label, value }) => (
                <Radio key={value} value={value}>
                  {label}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
          <Button
            mt={4}
            colorScheme="blue"
            onClick={handleClickExportCampaigns}
            isLoading={isExporting}
          >
            Exportar
          </Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

const WhatsappCampaignsPage = () => {
  const cardsData = [
    {
      icon: <MdCampaign />,
      title: "Total de campanhas",
      requestRoute: apiRoutes.getTotalWhatsappCampaigns(new Date(), new Date()),
      bgIconColor: colors.primaryLight,
    },
    {
      icon: <BiConversation />,
      title: "Total de disparos",
      requestRoute: apiRoutes.getTotalMesssagesSent(new Date(), new Date()),
      bgIconColor: colors.secondary,
      tooltip: "Total de mensagens enviadas em campanhas",
    },
    {
      icon: <PiArrowsClockwiseBold />,
      title: "Engajamento",
      requestRoute: apiRoutes.getMessagesEngagementRate(new Date(), new Date()),
      valueFormatter: (value: any) => `${(value || 0).toFixed(2)}%`,
      bgIconColor: colors.green,
      tooltip:
        "Percentual de mensagens que tiveram respostas ou cliques nos link",
    },
    {
      icon: <BiSolidMessageX />,
      title: "Bounce Rate",
      requestRoute: apiRoutes.getMessagesBounceRate(new Date(), new Date()),
      valueFormatter: (value: any) => `${(value || 0).toFixed(2)}%`,
      bgIconColor: colors.red,
      tooltip: "Percentual de mensagens que não foram entregues",
    },
  ];

  return (
    <Box padding="20px">
      <Header title="Campanhas de WhatsApp" icon={<FaWhatsapp />} />
      <Flex justifyContent="space-between" gap={3} alignItems='center'>
        {cardsData.map(
          ({
            icon,
            requestRoute,
            title,
            valueFormatter,
            bgIconColor,
            tooltip,
          }) => (
            <CardStatisticContainer
              key={title}
              icon={icon}
              title={title}
              requestRoute={requestRoute}
              valueFormatter={valueFormatter}
              bgIconColor={bgIconColor}
              tooltip={tooltip}
            />
          )
        )}
      </Flex>
      <Flex justifyContent={'space-between'} mt={10} alignItems='center'>
        <Heading size="md">Campanhas recentes</Heading>
        <ButtonExportCampaignResults />
      </Flex>
      <Divider orientation="horizontal" mt={2} />
      <TableWhatsappCampaigns />
    </Box>
  );
};

export default WhatsappCampaignsPage;
