import {
  createContext,
  ReactNode,
  useContext,
  useEffect, useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import sound from "../assets/sounds/notification-2.wav";
import { baseURL } from "../constants/base-url";
import { AuthService } from "../services/auth.service";
import {
  addMessagesToConversation,
  closeConversationTickets,
  insertConversation,
  updateConversation,
  updateMessageStatusByTempId,
  updateTicketAgentIdForConversation
} from "../state/inboxSlice";
import { RootState } from "../state/store";
import { CloseAllTicketsEventPayload } from "../types/CloseAllTicketsEventPayload";
import { NewConversationEventPayload } from "../types/NewConversationEventPayload";
import { NewConversationTicketEventPayload } from "../types/NewConversationTicketEventPayload";
import { NewMessageEventPayload } from "../types/NewMessageEventPayload";
import { UpdateConversationEventPayload } from "../types/UpdateConversationEventPayload";
import { UpdateMessageStatusEventPayload } from "../types/UpdateMessageStatusEventPayload";
import { UpdateConversationTicketAgentEventPayload } from "../types/UpdateConversationTicketAgentEventPayload";

const protocol = !!process.env.REACT_APP_REACT_DISABLE_SSL ? "ws" : "wss";
const SOCKET_URL = `${protocol}://${baseURL}`;
const audio = new Audio(sound);

export enum SocketEventsEnum {
  NEW_MESSAGE = "new.message",
  UPDATE_MESSAGE_STATUS = "update.message.status",

  NEW_CONVERSATION = "new.conversation",
  UPDATE_CONVERSATION = "update.conversation",
  CLOSE_ALL_CONVERSATION_TICKETS = "close.all.conversation.tickets",

  NEW_CONVERSATION_TICKET = "new.conversation.ticket",
  UPDATE_CONVERSATION_TICKET_AGENT = 'update.conversation.ticket.agent',
}

interface SocketContextData {
  socket: SocketIOClient.Socket;
}

interface SocketProviderProps {
  children?: ReactNode;
}

const SocketContext = createContext({} as SocketContextData);

export let socket: any;

export function SocketProvider({ children }: SocketProviderProps) {
  const { currentUser } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const [isTabActive, setIsTabActive] = useState(true);

  function playNotificationSound(muted = false) {
    const playPromise = audio.play();
    if (!!playPromise) {
      playPromise
        .then(() => {
          // Automatic playback started!
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      const tabState = document.visibilityState === "visible";

      if (tabState) {
        document.title = "Inbox - Revi";
      }

      setIsTabActive(tabState);
    });
  }, []);

  useEffect(() => {
    socket = io.connect(SOCKET_URL, {
      reconnectionDelayMax: 10000,
      auth: {
        token: AuthService.getAccessToken(),
      },
      transports: ["websocket", "polling"],
    });
    socket.on("connect", () => {
      console.log("Socket connected!");
    });

    socket.on("connection_error", () => {
      console.log("falha na conexão do socket");
      socket.connect();
    });

    socket.on(
      SocketEventsEnum.NEW_MESSAGE,
      (event: {
        event: string;
        data: NewMessageEventPayload;
      }) => {
        dispatch(
          addMessagesToConversation({
            conversationId: event.data.message.conversationId,
            messages: [event.data.message],
          })
        );

        if (!event.data.message.fromSystem) {
          if (!isTabActive) {
            const currentTotalUnreadMessages =
              document.title.match(/\((\d+)\)/);
            const newTotalUnreadMessages =
              (currentTotalUnreadMessages &&
                parseInt(currentTotalUnreadMessages[1], 10) + 1) ||
              1;
            document.title = `(${newTotalUnreadMessages}) Nova mensagem`;
            playNotificationSound();
          }
        }
      }
    );

    socket.on(
      SocketEventsEnum.UPDATE_MESSAGE_STATUS,
      (event: { event: string; data: UpdateMessageStatusEventPayload }) => {
        dispatch(
          updateMessageStatusByTempId({
            conversationId: event.data.conversationId,
            status: event.data.status,
            tempId: event.data.tempId,
          })
        );
      }
    );

    socket.on(
      SocketEventsEnum.NEW_CONVERSATION,
      (event: { event: string; data: NewConversationEventPayload }) => {
        dispatch(
          insertConversation({
            conversation: {
              id: event.data.conversation.id,
              lastMessage: event.data.conversation.messages[0],
              recipientName: event.data.conversation.recipientName,
              categoryId: event.data.conversation.categoryId,
              hasOpenTicket:
                event.data.conversation.conversationTickets.at(-1)?.status ===
                "open",
              ticketAgentId: event.data.conversation.conversationTickets.at(-1)?.agentId || null,
            },
          })
        );
      }
    );

    socket.on(
      SocketEventsEnum.CLOSE_ALL_CONVERSATION_TICKETS,
      (event: {
        event: string;
        data: CloseAllTicketsEventPayload;
      }) => {
        dispatch(
          closeConversationTickets({
            conversation: event.data.conversation,
          })
        );
      }
    );

    socket.on(
      SocketEventsEnum.NEW_CONVERSATION_TICKET,
      (event: {
        event: string;
        data: NewConversationTicketEventPayload;
      }) => {
        dispatch(
          insertConversation({
            conversation: {
              id: event.data.conversationTicket.conversation.id,
              lastMessage:
                event.data.conversationTicket.conversation.messages[0],
              recipientName:
                event.data.conversationTicket.conversation.recipientName,
              hasOpenTicket: event.data.conversationTicket.status === "open",
              categoryId: event.data.conversationTicket.conversation.categoryId,
              ticketAgentId: event.data.conversationTicket.agentId || null,
            },
          })
        );
      }
    );

    socket.on(
      SocketEventsEnum.UPDATE_CONVERSATION_TICKET_AGENT,
      (event: {
        event: string;
        data: UpdateConversationTicketAgentEventPayload;
      }) => {
        dispatch(
          updateTicketAgentIdForConversation({
            conversationId: event.data.conversationId,
            agentId: event.data.agentId,
            oldAgentId: event.data.oldAgentId,
          })
        )
      }
    )

    socket.on(
      SocketEventsEnum.UPDATE_CONVERSATION,
      (event: {
        event: string;
        data: UpdateConversationEventPayload;
      }) => {
        dispatch(
          updateConversation({
            currentConversation: event.data.currentConversation,
            previoustConversation: event.data.previoustConversation,
          })
        );
      }
    );

    return () => {
      socket.disconnect();
    };
  }, [currentUser, dispatch, isTabActive]);

  return (
    <SocketContext.Provider
      value={{
        socket,
      }}>
      {children}
    </SocketContext.Provider>
  );
}

export function useSocket(): SocketContextData {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
}
