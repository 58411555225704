import { AxiosPromise, AxiosResponse } from "axios";
import { request } from "../constants/request";
import { apiRoutes } from "../constants/api-routes";
import { ChartCampaignSalesData } from "../types/ChartCampaignSalesData";
import { RFMSummary } from "../types/RFMSummary";

const getTotalWhatsappCampaigns = async (startDate: Date, endDate: Date) => {
  return request.get(apiRoutes.getTotalWhatsappCampaigns(startDate, endDate));
};
const getTotalMesssagesSent = async (startDate: Date, endDate: Date) => {
  return request.get(apiRoutes.getTotalMesssagesSent(startDate, endDate));
};
const getMessagesBounceRate = async (startDate: Date, endDate: Date) => {
  return request.get(apiRoutes.getMessagesBounceRate(startDate, endDate));
};
const getMessagesEngagementRate = async (startDate: Date, endDate: Date) => {
  return request.get(apiRoutes.getMessagesEngagementRate(startDate, endDate));
};
const getChartCampaignPerformance = async (
  campaignId: string,
  timeUnit: "hour" | "day"
): Promise<
  AxiosResponse<{
    datetime: string;
    clicked_count: number;
    read_count: number;
    replied_count: number;
    total_orders_value: number;
  }>
> => {
  return request.get(
    apiRoutes.getChartCampaignPerformance(campaignId, timeUnit)
  );
};

const getChartCampaignSales = async (
  campaignId: string
): Promise<AxiosResponse<ChartCampaignSalesData>> => {
  return request.get(apiRoutes.getChartCampaignSales(campaignId));
};

export interface OrderAggByCustomerStatistics {
  averageOrderValue: number;
  averageTotalOrders: number;
  averageTotalPurchases: number;
  averageItemValue: number;
}

const getOrderAggByCustomerStatistics = async (
  startOrdersCreatedAt?: string,
  endOrdersCreatedAt?: string
): Promise<AxiosResponse<OrderAggByCustomerStatistics>> => {
  return request.get(
    apiRoutes.getOrderAggByCustomerStatistics(
      startOrdersCreatedAt,
      endOrdersCreatedAt
    )
  );
};

const getChartAutomationSales = async (
  automationId: string,
  startDate: Date,
  endDate: Date
): Promise<
  AxiosResponse<{
    received: {
      totalOrders: number;
      totalOrdersValue: number;
      roi: number;
      count: number;
    };
    read: {
      totalOrders: number;
      totalOrdersValue: number;
      roi: number;
      count: number;
    };
    engaged: {
      totalOrders: number;
      totalOrdersValue: number;
      roi: number;
      count: number;
    };
    startDate: Date;
    endDate: Date;
    automationCost: number;
    totalMessagesSent: number;
  }>
> => {
  return request.get(
    apiRoutes.getChartAutomationSales(automationId, startDate, endDate)
  );
};

const getChartAutomationPerformance = async (
  automationId: string,
  startDate: Date,
  endDate: Date
): Promise<
  AxiosResponse<
    {
      datetime: string;
      orders_count: number;
      total_orders_value: number;
      messages_count: number;
    }[]
  >
> => {
  return request.get(
    apiRoutes.getChartAutomationPerformance(automationId, startDate, endDate)
  );
};

const getReviRevenueSummary = (): AxiosPromise<{
  marketing: number;
  automation: number;
  total: number;
}> => {
  return request.get(apiRoutes.getReviRevenueSummary());
};

const getCustomersKpiSummary = (): AxiosPromise<{
  avgOrderValuePerCustomer: null | number;
  avgTotalOrdersPerCustomer: null | number;
  avgTotalOrdersValuePerCustomer: null | number;
}> => {
  return request.get(apiRoutes.getCustomersKpiSummary());
};

const getRFMAnalysis = (): AxiosPromise<RFMSummary> => {
  return request.get(apiRoutes.getRFMAnalysis());
};

const getOrdersCountByWeekDayAndHour = (): AxiosPromise<{
  weekDay: number;
  hour: number;
  count: number;
}[]> => {
  return request.get(apiRoutes.getOrdersCountByWeekDayAndHour());
};

export const StatisticsService = {
  getOrderAggByCustomerStatistics,
  getTotalWhatsappCampaigns,
  getTotalMesssagesSent,
  getMessagesBounceRate,
  getMessagesEngagementRate,
  getChartCampaignPerformance,
  getChartCampaignSales,
  getChartAutomationSales,
  getChartAutomationPerformance,
  getReviRevenueSummary,
  getCustomersKpiSummary,
  getRFMAnalysis,
  getOrdersCountByWeekDayAndHour,
};
