import {
  MessageTemplate,
  MessageTemplateWithIncludes,
} from "./../types/MessageTemplate.d";
import { AxiosResponse } from "axios";
import { apiRoutes } from "../constants/api-routes";
import { request } from "../constants/request";
import { ButtonType } from "../types/ButtonTypeEnum";
import { GupshupTemplateType } from "../types/GupshupTemplateType";
import { CommunicationChannelEnum } from "../types/CommunicationChannelEnum";
import { MessageTemplateButton } from "../types/MessageTemplateButton";
import { WhatsappTemplateCategory } from "../types/Prisma";

export interface ListMessageTemplateItem extends MessageTemplate {
  messageTemplateButtons: (Pick<MessageTemplateButton, "text" | "url"> & {
    type: ButtonType;
    url?: string;
  })[];
}

const showMessageTemplate = async (
  id: string
): Promise<AxiosResponse<MessageTemplateWithIncludes>> => {
  return request.get(apiRoutes.showMessageTemplate(id));
};

const listMessageTemplates = async (): Promise<
  AxiosResponse<ListMessageTemplateItem[]>
> => {
  return request.get(apiRoutes.listMessageTemplates());
};

export interface GenerateMessageTemplateDto {
  evento: string;
  valorDoCupom: string;
  codigoDoCupom: string;
  dataDeValidade: string;
}
const generateMessageTemplate = async (
  generateMessageTemplateDto: GenerateMessageTemplateDto
): Promise<AxiosResponse<{ content: string; promptThreadId: string }>> => {
  return request.post(
    apiRoutes.generateMessageTemplate(),
    generateMessageTemplateDto
  );
};

export interface RegenerateMessageTemplateDto {
  promptThreadId: string;
  promptText: string;
}
const regenerateMessageTemplate = async (
  regenerateMessageTemplateDto: RegenerateMessageTemplateDto
): Promise<AxiosResponse<{ content: string; promptThreadId: string }>> => {
  return request.post(
    apiRoutes.regenerateMessageTemplate(),
    regenerateMessageTemplateDto
  );
};

export interface Button {
  type: ButtonType;
  text: string;
  url?: string;
}

export interface CreateMessageTemplateDto {
  elementName: string;
  templateType: GupshupTemplateType;
  content: string;
  buttons?: Button[];
  file?: File;
  footer?: string;
  messageTemplateSuggestionId?: string;
  communicationChannel: CommunicationChannelEnum;
  whatsappTemplateCategory: WhatsappTemplateCategory;
}

const createMessageTemplate = async (
  createMessageTemplateDto: CreateMessageTemplateDto
) => {
  const data = new FormData();

  data.append("elementName", createMessageTemplateDto.elementName);
  data.append("templateType", createMessageTemplateDto.templateType);
  data.append("whatsappTemplateCategory", createMessageTemplateDto.whatsappTemplateCategory);
  data.append(
    "communicationChannel",
    createMessageTemplateDto.communicationChannel
  );
  data.append("content", createMessageTemplateDto.content);
  if (!!createMessageTemplateDto.footer) {
    data.append("footer", createMessageTemplateDto.footer);
  }
  if (!!createMessageTemplateDto.buttons) {
    data.append("buttons", JSON.stringify(createMessageTemplateDto.buttons));
  }
  if (!!createMessageTemplateDto.file) {
    data.append("file", createMessageTemplateDto.file);
  }

  if (!!createMessageTemplateDto.messageTemplateSuggestionId) {
    data.append(
      "messageTemplateSuggestionId",
      createMessageTemplateDto.messageTemplateSuggestionId
    );
  }

  return request.post(apiRoutes.createMessageTemplate(), data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    timeout: 60000,
  });
};

export interface UpdateMessageTemplateDto {
  id: string;
  ctaLink?: string | null;
  file?: File | null;
}

const updateMessageTemplate = async (
  updateMessageTemplateDto: UpdateMessageTemplateDto
) => {
  const data = new FormData();

  if (!!updateMessageTemplateDto.file) {
    data.append("file", updateMessageTemplateDto.file);
  }

  if (!!updateMessageTemplateDto.ctaLink) {
    data.append("ctaLink", updateMessageTemplateDto.ctaLink);
  }

  return request.put(
    apiRoutes.updateMessageTemplate(updateMessageTemplateDto.id),
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 60000,
    }
  );
};

const deleteMessageTemplate = async (
  messageTemplateId: string
): Promise<AxiosResponse<MessageTemplate>> => {
  return request.delete(apiRoutes.deleteMessageTemplate(messageTemplateId));
};

export const MessageTemplatesService = {
  showMessageTemplate,
  listMessageTemplates,
  createMessageTemplate,
  generateMessageTemplate,
  regenerateMessageTemplate,
  updateMessageTemplate,
  deleteMessageTemplate,
};
