import { Checkbox } from "@chakra-ui/react";
import { useState } from "react";
import { useController } from "react-hook-form";

interface InputCheckboxProps {
  options: {
    label: string;
    value: string;
  }[];
  control: any;
  name: string;
  itemWidth?: string;
}

const InputCheckbox = ({ options, control, name, itemWidth }: InputCheckboxProps) => {
  const { field } = useController({
    control,
    name,
  });
  const [value, setValue] = useState(field.value || []);

  return (
    <div>
      {options.map(({ label, value: option }, index) => (
        <Checkbox
          onChange={(e) => {
            let valueCopy = [...value];

            // update checkbox value
            valueCopy[index] = e.target.checked ? e.target.value : null;

            // send data to react hook form
            field.onChange(valueCopy);

            // update local state
            setValue(valueCopy);
          }}
          key={option}
          value={option}
          width={itemWidth}
        >
          {label}
        </Checkbox>
      ))}
    </div>
  );
};

export default InputCheckbox;
