import { ExperimentStatus } from './../../types/CampaignExperiment';
export default {
  translation: {
    weekdays: {
      short: {
        sun: "Dom",
        mon: "Seg",
        tue: "Ter",
        wed: "Qua",
        thu: "Qui",
        fri: "Sex",
        sat: "Sáb",
      },
      long: {
        sunday: "Domingo",
        monday: "Segunda-feira",
        tuesday: "Terça-feira",
        wednesday: "Quarta-feira",
        thursday: "Quinta-feira",
        friday: "Sexta-feira",
        saturday: "Sábado",
      },
    },  
    enums: {
      FlowTriggerType: {
        exact_match: "Mensagem igual",
        keyword_match: "Mensagem contém",
      },
      FlowNodeType: {
        trigger: "Trigger",
        send_whatsapp_message: "Enviar mensagem",
        move_conversation_to_category: "Mover conversa para categoria",
        send_whatsapp_media: "Enviar mídia",
        add_tag_to_customer: "Adicionar Tag ao Cliente",
        end_whatsapp_conversation: "Finalizar Conversa",
      },
      ConversationTicketStatus: {
        open: "Aberto",
        closed: "Finalizado",
      },
      WhatsappCampaignStatus: {
        in_progress: "Em progresso",
        completed: "Finalizada",
        scheduled: "Agendada",
        canceled: "Cancelada",
        interrupted: "Interrompida",
        failed: "Falhou",
      },
      ExperimentStatus: {
        scheduled: 'Agendado',
        in_progress: 'Em progresso',
        completed: 'Finalizado',
        canceled: 'Cancelado',
      },
      SmsCampaignStatus: {
        in_progress: "Em progresso",
        completed: "Finalizada",
        scheduled: "Agendada",
        canceled: "Cancelada",
        failed: "Falhou",
      },
      MessageTemplateStatus: {
        pending: "Pendente",
        rejected: "Rejeitado",
        approved: "Aprovado",
        deleted: "Deletado",
        disabled: "Desativado",
        paused: "Pausado",
      },
      RFMGroup: {
        champions: 'Campeões',
        loyal_customers: 'Clientes fiéis',
        potential_loyalists: 'Potenciais fiéis',
        recent_customers: 'Clientes recentes',
        promising: 'Promissores',
        need_attention: 'Precisam de atenção',
        about_to_sleep: 'Prestes a dormir',
        cannot_lose_them: 'Não pode perder',
        at_risk: 'Em risco',
        hibernating: 'Hibernando',
        lost_customers: 'Perdidos',
      }
    },
  },
};
