import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  IconButton,
  Switch,
  Text,
  useDisclosure,
  useEditableControls,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { AiOutlineMessage } from "react-icons/ai";
import { FaAngleLeft, FaExchangeAlt, FaTag, FaDotCircle } from "react-icons/fa";
import { MdOutlinePermMedia } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import ReactFlow, {
  addEdge,
  Background,
  Controls,
  MiniMap,
  Node,
  NodeChange,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useUpdateNodeInternals,
} from "reactflow";
import "reactflow/dist/style.css";
import { v4 as uuidv4 } from "uuid";
import AlertDialogBase from "../../../../components/AlertDialog";
import ButtonIcon from "../../../../components/ButtonIcon";
import { apiRoutes } from "../../../../constants/api-routes";
import { appPaths } from "../../../../constants/app-paths";
import useOutsideClickDetector from "../../../../hooks/useOutsideClickDetector";
import {
  FlowsService,
  ShowFlowResponse,
  UpdateFlowDto,
} from "../../../../services/flows.service";
import { NodeData, NodeType } from "../../../../types/ReactFlowNode";
import { ReactFlowUtils } from "../../../../utils/react-flow.utils";
import DrawerNodeEditor from "./components/DrawerNodeEditor";
import MoveConversationToCategoryNode from "./components/MoveConversationToCategoryNode";
import SendWhatsappMediaNode from "./components/SendWhatsappMediaNode";
import SendWhatsappMessageNode from "./components/SendWhatsappMessageNode";
import TriggerNode from "./components/TriggerNode";
import AddTagToCustomerNode from "./components/AddTagToCustomerNode";
import EndWhatsappConversationNode from "./components/EndWhatsappConversationNode";

const nodeTypes: Record<NodeType, any> = {
  send_whatsapp_message: SendWhatsappMessageNode,
  send_whatsapp_media: SendWhatsappMediaNode,
  trigger: TriggerNode,
  move_conversation_to_category: MoveConversationToCategoryNode,
  add_tag_to_customer: AddTagToCustomerNode,
  end_whatsapp_conversation: EndWhatsappConversationNode,
};

const FlowEditorPage = () => {
  const navigate = useNavigate();
  const { flowId } = useParams();
  const [flow, setFlow] = useState<ShowFlowResponse | null>(null);
  const reactFlowWrapper = useRef<any>(null);
  const {
    isOpen: isAlertOpen,
    onClose: onCloseAlert,
    onOpen: onOpenAlert,
  } = useDisclosure();
  const toast = useToast();
  useQuery(
    apiRoutes.showFlow(flowId!),
    async () => {
      const { data } = await FlowsService.showFlow(flowId!);
      return data;
    },
    {
      onSuccess: (data) => {
        setFlow(data);
      },
    }
  );
  const updateFlow = useMutation(
    apiRoutes.updateFlow(flowId!),
    async (updateFlowDto: UpdateFlowDto) => {
      const { data } = await FlowsService.updateFlow(flowId!, updateFlowDto);
      return data;
    },
    {
      onSuccess: () => {
        toast({
          title: "Fluxo atualizado com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate(appPaths.automations.messageFlows.index());
      },
    }
  );

  const updateNodeInternals = useUpdateNodeInternals();
  const { getNode, project } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedNode, setSelectedNode] = useState<Node | null>(null);
  const [menuPosition, setMenuPosition] = useState<{
    flowX: number;
    flowY: number;
    offsetX: number;
    offsetY: number;
  } | null>(null);
  const {
    isOpen: isNodeModalOpen,
    onOpen: onNodeModalOpen,
    onClose: onNodeModalClose,
  } = useDisclosure();
  const [flowTitle, setFlowTitle] = useState<string>("");
  const [isFlowActive, setIsFlowActive] = useState<boolean>(false);
  const connectionCreated = useRef(false);
  const menuRef = useRef<any>(false);
  useOutsideClickDetector(menuRef, () => setMenuPosition(null));

  useEffect(() => {
    if (flow) {
      setFlowTitle(flow.title);
      setIsFlowActive(flow.isActive);
      setNodes(ReactFlowUtils.extractNodesFromFlow(flow));
      setEdges(ReactFlowUtils.extractEdgesFromFlow(flow));
    }
  }, [flow, setNodes, setEdges]);

  const onConnectStart = useCallback(() => {
    connectionCreated.current = false;
  }, []);

  const onConnect = useCallback(
    (params: any) => {
      connectionCreated.current = true;
      setEdges((eds) => addEdge(params, eds));
    },
    [setEdges]
  );

  const getReactFlowPositionFromEvent = useCallback(
    (e: any) => {
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      return project({
        x: e.clientX - reactFlowBounds.left,
        y: e.clientY - reactFlowBounds.top,
      });
    },
    [project]
  );

  const onContextMenu = useCallback(
    (e: any) => {
      e.preventDefault();
      const { clientX, clientY } = e;
      const flowPosition = getReactFlowPositionFromEvent(e);

      setMenuPosition({
        flowX: flowPosition.x,
        flowY: flowPosition.y,
        offsetX: clientX - 50, // sidebar width
        offsetY: clientY - 40, // header height
      });
    },
    [getReactFlowPositionFromEvent]
  );

  useEffect(() => {
    removeInvalidEdges();
  }, [JSON.stringify(nodes)]);

  function handleNodeChange(changes: NodeChange[]) {
    const nextChanges = changes.reduce((acc, change) => {
      if (change.type === "remove") {
        const node = getNode(change.id);
        if (node && node.type === "trigger") return acc;
      }

      if (change.type === "position") {
        const node = getNode(change.id);
        if (node && node.type === "trigger") return acc;
      }

      return [...acc, change];
    }, [] as NodeChange[]);

    onNodesChange(nextChanges);
  }

  function handleNodeClick(event: React.MouseEvent, node: Node) {
    setSelectedNode(node);
    onNodeModalOpen();
  }

  async function handleSaveFlow() {
    const TriggerNode = nodes.find((node) => node.type === "trigger");
    const flowTriggers = TriggerNode?.data.flowTriggers || [];
    await updateFlow.mutateAsync({
      title: flowTitle,
      isActive: isFlowActive,
      edges,
      nodes: nodes.filter((node) => node.type !== "trigger"),
      flowTriggers,
    });
  }

  function handleSaveNode(data: any) {
    setNodes((nds) => {
      return nds.map((node) => {
        if (node.id === selectedNode!.id) {
          return {
            ...node,
            data,
          };
        }
        return node;
      });
    });
    updateNodeInternals(selectedNode!.id);
    onNodeModalClose();
  }

  function deleteNode(nodeId: string) {
    setNodes((nds) => nds.filter((node) => node.id !== nodeId));
    onNodeModalClose();
    setSelectedNode(null);
  }

  function removeInvalidEdges() {
    setEdges((eds) => {
      return eds.filter((edge) => {
        const isSourceValid = nodes.some((node) => {
          if (
            node.type === "send_whatsapp_message" &&
            node.data.buttons &&
            node.data.buttons.length > 0 &&
            !node.data.buttons[0].url
          ) {
            return node.data.buttons.some(
              (button: any) => button.id === edge.sourceHandle
            );
          }
          if (
            node.type === "trigger" &&
            node.data.flowTriggers &&
            node.data.flowTriggers.length > 0
          ) {
            return node.data.flowTriggers.some(
              (trigger: any) => trigger.id === edge.sourceHandle
            );
          }

          return node.id === edge.source && edge.source === edge.sourceHandle;
        });
        const isTargetValid = nodes.some(
          (node) => node.id === edge.target || node.id === edge.targetHandle
        );

        return isSourceValid && isTargetValid;
      });
    });
  }

  function createNewNode(nodeType: NodeType) {
    const nodeData: Record<NodeType, NodeData> = {
      trigger: {
        flowTriggers: [],
      },
      move_conversation_to_category: {
        targetConversationCategoryId: "",
      },
      send_whatsapp_message: {
        text: "",
        buttons: [],
      },
      send_whatsapp_media: {
        file: undefined,
        fileId: "",
        fileKey: "",
        mediaType: undefined,
      },
      add_tag_to_customer: {
        tagId: "",
      },
      end_whatsapp_conversation: {
        text: "",
      },
    };
    const newNode = {
      id: uuidv4(),
      type: nodeType,
      position: { x: menuPosition!.flowX, y: menuPosition!.flowY },
      data: nodeData[nodeType],
    };

    setNodes((nds) => [newNode, ...nds]);
    setSelectedNode(newNode);
    onNodeModalOpen();
    setMenuPosition(null);
  }

  return (
    <Flex flexDir="column" width="100%" height="100%">
      <Flex justifyContent={"space-between"} alignItems="center" padding={2}>
        <Flex alignItems={"center"} gap={2}>
          <ButtonIcon icon={<FaAngleLeft />} onClick={() => onOpenAlert()} />
          <Editable
            value={flowTitle}
            onChange={setFlowTitle}
            fontWeight="bold"
            fontSize="lg"
            gap={2}
            display="flex"
            isPreviewFocusable={false}
          >
            <EditablePreview />
            <EditableInput />
            <EditableControls />
          </Editable>
        </Flex>
        <Flex>
          <Text fontWeight="bold" marginRight={2}>
            Ativo?
          </Text>
          <Switch
            isChecked={isFlowActive}
            onChange={(e) => setIsFlowActive(e.target.checked)}
          />
        </Flex>
        <Button isLoading={updateFlow.isLoading} onClick={handleSaveFlow}>
          Salvar mudanças
        </Button>
      </Flex>
      <Box ref={reactFlowWrapper} height="100%">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={handleNodeChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          onConnectStart={onConnectStart}
          onNodeClick={handleNodeClick}
          onContextMenu={onContextMenu}
          minZoom={0.3}
          style={{ position: "relative", flex: 1 }}
        >
          <Controls position="top-right" />
          <MiniMap position="bottom-left" />
          <Background gap={12} size={1} />
          <Box
            display={menuPosition ? "block" : "none"}
            ref={menuRef}
            position="absolute"
            left={menuPosition?.offsetX}
            top={menuPosition?.offsetY}
            right="auto"
            bottom="auto"
            zIndex={10}
            padding="10px"
            boxShadow="sm"
            bg="#3f3f3f"
            color="white"
            rounded="md"
          >
            <Flex
              onClick={() => createNewNode("send_whatsapp_message")}
              alignItems="center"
              gap={2}
              _hover={{ cursor: "pointer", bg: "#5b5b5b" }}
            >
              <AiOutlineMessage /> Enviar whatsapp
            </Flex>
            <Flex
              onClick={() => createNewNode("send_whatsapp_media")}
              alignItems="center"
              gap={2}
              _hover={{ cursor: "pointer", bg: "#5b5b5b" }}
            >
              <MdOutlinePermMedia /> Enviar mídia
            </Flex>
            <Flex
              onClick={() => createNewNode("move_conversation_to_category")}
              alignItems="center"
              gap={2}
              _hover={{ cursor: "pointer", bg: "#5b5b5b" }}
            >
              <FaExchangeAlt /> Mover conversa para categoria
            </Flex>
            <Flex
              onClick={() => createNewNode("add_tag_to_customer")}
              alignItems="center"
              gap={2}
              _hover={{ cursor: "pointer", bg: "#5b5b5b" }}
            >
              <FaTag /> Adicionar Tag
            </Flex>
            <Flex
              onClick={() => createNewNode("end_whatsapp_conversation")}
              alignItems="center"
              gap={2}
              _hover={{ cursor: "pointer", bg: "#5b5b5b" }}
            >
              <FaDotCircle /> Finalizar Conversa
            </Flex>
          </Box>
          {nodes.length <= 1 && (
            <Box
              position="absolute"
              bgColor="#293845"
              color="white"
              borderRadius={"15px"}
              padding="10px"
              top="40px"
              left="50%"
              transform={"translate(-50%, 0)"}
            >
              <Text>
                Clique com o botão direito do mouse para adicionar um novo bloco
              </Text>
            </Box>
          )}
        </ReactFlow>
      </Box>
      {selectedNode && (
        <DrawerNodeEditor
          onDeleteNode={(nodeId) => deleteNode(nodeId)}
          isOpen={isNodeModalOpen}
          onClose={onNodeModalClose}
          selectedNode={selectedNode}
          onSaveNode={handleSaveNode}
        />
      )}
      <AlertDialogBase
        isOpen={isAlertOpen}
        onClose={onCloseAlert}
        title="Salvar alterações?"
        buttonRejectText="Descartar"
        buttonConfirmText="Salvar"
        buttonConfirmColor="blue"
        onConfirm={async () => {
          onCloseAlert();
          await handleSaveFlow();
        }}
        onReject={() => {
          navigate(appPaths.automations.messageFlows.index());
        }}
      >
        Deseja salvar as alterações?
      </AlertDialogBase>
    </Flex>
  );
};

function EditableControls() {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls();

  return isEditing ? (
    <ButtonGroup justifyContent="center" size="sm">
      <IconButton icon={<CheckIcon />} {...(getSubmitButtonProps() as any)} />
      <IconButton icon={<CloseIcon />} {...(getCancelButtonProps() as any)} />
    </ButtonGroup>
  ) : (
    <Flex justifyContent="center">
      <IconButton
        size="sm"
        icon={<EditIcon />}
        {...(getEditButtonProps() as any)}
      />
    </Flex>
  );
}

export default FlowEditorPage;
