export enum WhatsappTemplateCategory {
  MARKETING = 'MARKETING',
  UTILITY = 'UTILITY',
  AUTHENTICATION = 'AUTHENTICATION'
}

export enum WhatsappTemplateCategoryText {
  MARKETING = 'Marketing & Engajamento',
  UTILITY = 'Feedback & Rastreamento do Pedido',
  AUTHENTICATION = 'Autenticação'
}
