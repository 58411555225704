import { PaginatedResponse } from "./../types/PaginatedResponse.d";
import { AxiosResponse } from "axios";
import { apiRoutes } from "../constants/api-routes";
import { request } from "../constants/request";
import { CustomerWithIncludes } from "../types/Customer";
import { EngagementActionsEnum } from "../types/EngagementActionsEnum";
import { FormDataUtils } from "../utils/form-data.utils";
import { TableHeaders } from "../types/TableHeaders";

export interface UploadCustomersDto {
  file: File;
  mappedHeaders: {
    name?: string;
    birthDate?: string;
    email?: string;
    phoneNumberId?: string;
    tags?: string;
  };
}

const uploadCustomers = async (uploadCustomersDto: UploadCustomersDto) => {
  const { file, mappedHeaders } = uploadCustomersDto;
  const data = FormDataUtils.convertJsonToFormData({
    file,
    ...mappedHeaders,
  });

  return request.post(apiRoutes.uploadCustomers(), data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    timeout: 60000,
  });
};

export interface ListCustomersParams {
  excludedTemplateIds?: string | null;
  selectedTemplateId?: string | null;
  selectedEngagementActionTypes?: EngagementActionsEnum | null | "";
  page?: number | null;
  perPage?: number | null;
}

const listCustomers = async (
  listCustomerParams: ListCustomersParams
): Promise<AxiosResponse<PaginatedResponse<CustomerWithIncludes>>> => {
  return request.get(apiRoutes.listCustomers(listCustomerParams));
};

const toggleOptOutCustomer = async (customerId: string) => {
  return request.put(apiRoutes.toggleOptOutCustomer(customerId));
};

const listCustomerTableHeaders = async (): Promise<
  AxiosResponse<TableHeaders[]>
> => {
  return request.get(apiRoutes.listCustomerTableHeaders());
};

const listOptedOutCustomers = async (): Promise<
  AxiosResponse<{ customerIds: string[] }>
> => {
  return request.get(apiRoutes.listOptedOutCustomers());
};

export const CustomersService = {
  uploadCustomers,
  listCustomers,
  toggleOptOutCustomer,
  listCustomerTableHeaders,
  listOptedOutCustomers,
};
