import { AxiosResponse } from "axios";
import { apiRoutes } from "../constants/api-routes";
import { request } from "../constants/request";
import { Company } from "../types/Company";
import {
  CompanyDefinedField,
  CompanyDefinedFieldDataTypeEnum,
  CompanyDefinedFieldTableEnum,
} from "../types/CompanyDefinedField";
import { parseJwt } from "../utils/parse-jwt.utils";

const getCompanyDetails = (): Promise<AxiosResponse<Company>> => {
  return request.get(apiRoutes.getCompanyDetails());
};

export interface UpdateCompanyDto {
  firstContactMessage: string;
  isAutomaticSortingActive: boolean;
  initialContactFlowId?: string;
}

const updateCompany = (updateCompanyDto: UpdateCompanyDto) => {
  const accessToken = localStorage.getItem("access_token");
  const userData = parseJwt(accessToken!);
  return request.put(
    apiRoutes.updateCompany(userData!.companyId),
    updateCompanyDto
  );
};

const getCompanyMessageBalance = (): Promise<
  AxiosResponse<{
    daily: {
      total: number;
      sent: number;
    };
    monthly: {
      total: number;
      sent: number;
      billable: number;
    };
    canSend: boolean;
  }>
> => {
  return request.get(apiRoutes.getCompanyMessageBalance());
};

const listCompanyDefinedFields = (
  table: CompanyDefinedFieldTableEnum
): Promise<AxiosResponse<CompanyDefinedField[]>> => {
  return request.get(apiRoutes.listCompanyDefinedFields(table));
};

export interface CreateCompanyDefinedFieldDto {
  companyId: string;
  // table: CompanyDefinedFieldTableEnum;
  name: string;
  dataType: CompanyDefinedFieldDataTypeEnum;
}

const createCompanyDefinedField = (
  createCompanyDefinedFieldDto: CreateCompanyDefinedFieldDto
) => {
  return request.post(
    apiRoutes.createCompanyDefinedField(),
    createCompanyDefinedFieldDto
  );
};

export const CompaniesService = {
  getCompanyDetails,
  updateCompany,
  getCompanyMessageBalance,
  listCompanyDefinedFields,
  createCompanyDefinedField,
};
