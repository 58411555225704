import { Box, Flex, FormControl, FormLabel, Input, Select, Switch, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import WhatsappTemplatePreview from '../../../../../components/WhatsappTemplatePreview';
import { ListMessageTemplateItem } from '../../../../../services/message-templates.service';
import { CompanyDefinedField } from '../../../../../types/CompanyDefinedField';
import { MessageTemplateUtils } from '../../../../../utils/message-templates.utils';

interface TemplateParameterEditorProps {
  template: ListMessageTemplateItem;
  onChangeTemplateArgs: (args: Record<string, string>) => void;
  templateArgs: Record<string, string>;
  companyDefinedFields: CompanyDefinedField[];
}

const TemplateParameterEditor = ({
  template,
  onChangeTemplateArgs,
  templateArgs,
  companyDefinedFields,
}: TemplateParameterEditorProps) => {
  const [dynamicInputFields, setDynamicInputFields] = useState<
    Record<string, boolean>
  >({});

  useEffect(() => {
    Object.entries(templateArgs).map(([key, value]) => {
      if (value.startsWith('@')) {
        setDynamicInputFields((prev) => {
          return {
            ...prev,
            [key]: true,
          };
        });
      }
    })
  }, [templateArgs])

  useEffect(() => {
    return () => {
      setDynamicInputFields({});
    };
  }, [])

  function handleChangeDynamicInputField(param: string) {
    onChangeTemplateArgs({
      [param]: "",
    })
    setDynamicInputFields((prev) => {
      return {
        ...prev,
        [param]: !prev[param],
      };
    });
  }

  function handleChangeTemplateParameter(parameter: string, value: string) {
    onChangeTemplateArgs({
      [parameter]: value,
    });
  }

  const customParameters = MessageTemplateUtils.getCustomParametersInText(
    template?.templateText || ""
  );

  return (
    <Flex gap={5}>
      <WhatsappTemplatePreview
        message={template.templateText}
        footer={template.footerText}
        buttons={template.messageTemplateButtons}
        fileUrl={template.mediaUrl}
        height={"60vh"}
      />
      <Box flex={1}>
        <Text fontWeight={"bold"}>Parâmetros customizados</Text>
        {customParameters.length === 0 ? (
          <Box>
            <Text>Não há parâmetros customizados</Text>
          </Box>
        ) : (
          <Box>
            {customParameters.map((param) => (
              <FormControl isRequired>
                <FormLabel>
                  {param.replaceAll("[", "").replaceAll("]", "")}
                </FormLabel>
                <FormControl display="flex" alignItems="center" mb={1}>
                  <FormLabel mb={0} fontSize={"12px"}>
                    Usar valor da coluna?
                  </FormLabel>
                  <Switch
                    isChecked={dynamicInputFields[param]}
                    size="sm"
                    onChange={() => handleChangeDynamicInputField(param)}
                  />
                </FormControl>
                {dynamicInputFields[param] ? (
                  <Select
                    placeholder="Selecione uma coluna"
                    onChange={(e) =>
                      handleChangeTemplateParameter(param, e.target.value)
                    }
                    defaultValue={templateArgs[param]}
                  >
                    {(companyDefinedFields || []).map((companyDefinedField) => (
                      <option
                        key={companyDefinedField.id}
                        value={`@${companyDefinedField.name}`}>
                        {companyDefinedField.name}
                      </option>
                    ))}
                  </Select>
                ) : (
                  <Input
                    onChange={(e) =>
                      handleChangeTemplateParameter(param, e.target.value)
                    }
                    value={templateArgs[param] || ""}
                    required
                  />
                )}
              </FormControl>
            ))}
          </Box>
        )}
      </Box>
  </Flex>
  )
}

export default TemplateParameterEditor