import React from 'react'
import { AudienceRecommendation, AudienceRecommendationTypeEnum } from '../../../../../types/AudienceRecommendation'
import innactiveCustomerIcon from "../../../../../assets/images/inactive-customer-icon.png";
import newCustomerIcon from "../../../../../assets/images/new-customer-icon.png";
import productLaunchIcon from "../../../../../assets/images/product-launch-icon.png";
import promotionIcon from "../../../../../assets/images/promotion-icon.png";
import { Box, Flex, Image, Text, useRadioGroup } from '@chakra-ui/react';
import RadioOptionWrapper from '../../../../../components/RadioOptionWrapper';

const audienceImagePathByGoal: Record<AudienceRecommendationTypeEnum, string> = {
  [AudienceRecommendationTypeEnum.NEW_CUSTOMERS]: newCustomerIcon,
  [AudienceRecommendationTypeEnum.INACTIVE_CUSTOMERS]: innactiveCustomerIcon,
  [AudienceRecommendationTypeEnum.PRODUCT_LAUNCH]: productLaunchIcon,
  [AudienceRecommendationTypeEnum.PROMOTION]: promotionIcon,
};

interface AudienceRecommendationSelectorProps {
  audienceRecommendations: AudienceRecommendation[];
  onClickRecommendation: (recommendation: null | AudienceRecommendation) => void;
}

const AudienceRecommendationSelector = ({
  audienceRecommendations,
  onClickRecommendation
}: AudienceRecommendationSelectorProps) => {
  const { getRadioProps, getRootProps } = useRadioGroup({
    name: "audienceType",
    defaultValue: "none",
    onChange: (value) => {
      const selectedAudience = audienceRecommendations.find(
        (audience) => audience.type === value
      );
      onClickRecommendation(selectedAudience || null);
    },
  });

  function getLabelByRecommendationType(type: AudienceRecommendationTypeEnum) {
    const labels: Record<AudienceRecommendationTypeEnum, string> = {
      [AudienceRecommendationTypeEnum.NEW_CUSTOMERS]: "Fidelizar clientes novos",
      [AudienceRecommendationTypeEnum.INACTIVE_CUSTOMERS]:
        "Recuperar clientes inativos",
      [AudienceRecommendationTypeEnum.PRODUCT_LAUNCH]: "Lançamento",
      [AudienceRecommendationTypeEnum.PROMOTION]: "Promoção",
    };
    return labels[type] || "";
  }

  const audienceOptions: {
    value: string;
    label: string;
    imageSrc: string;
  }[] =
    audienceRecommendations?.map((audience) => ({
      value: audience.type,
      label: getLabelByRecommendationType(audience.type),
      imageSrc: audienceImagePathByGoal[audience.type],
    })) || [];


  return (
    <Flex flexDir='row' gap={2} overflowX='scroll'>
      {[
        {
          value: "none",
          label: "Nenhuma",
          imageSrc: "",
        },
        ...audienceOptions,
      ].map((option, index) => {
        const value = option.value;
        const radio = getRadioProps({ value });
        return (
          <RadioOptionWrapper key={value} radioProps={radio}>
            <Box
              display="flex"
              flexDir="column"
              alignItems="center"
              justifyContent={index === 0 ? "center" : ""}
              padding={2}
              height="140px"
              width="100px"
              gap={2}>
              {index !== 0 && (
                <Image
                  src={option.imageSrc}
                  alt={option.value}
                  boxSize="50px"
                />
              )}
              <Box>
                <Text fontSize="sm" textAlign="center">
                  {option.label}
                </Text>
              </Box>
            </Box>
          </RadioOptionWrapper>
        );
      })}
    </Flex>
  )
}

export default AudienceRecommendationSelector