// Supported media types (16/07/2022) - https://developers.facebook.com/docs/whatsapp/cloud-api/reference/media#supported-media-types

const supportedDocumentTypes = [
  "text/csv",
  "text/plain",
  "application/pdf",
  "application/vnd.ms-powerpoint",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
const supportedAudioTypes = [
  "audio/aac",
  "audio/mp4",
  "audio/mpeg",
  "audio/amr",
  "audio/ogg; codecs=opus",
  "audio/ogg",
];
const supportedVideoTypes = ["video/mp4", "video/3gp"];
const supportedImageTypes = ["image/jpeg", "image/png"];
const supportedFileTypes = [
  ...supportedDocumentTypes,
  ...supportedAudioTypes,
  ...supportedVideoTypes,
  ...supportedImageTypes,
];

const isValidFileType = (file: File): boolean => {
  const fileType = file.type;
  return supportedFileTypes.includes(fileType);
};

function limitByFileType(fileType: string, unit?: "MB" | "KB") {
  const bytesInOneMB = 1000 * 1000;
  let limit = 0;
  if (supportedDocumentTypes.includes(fileType)) {
    limit = 100 * bytesInOneMB;
  }
  if (supportedAudioTypes.includes(fileType)) {
    limit = 16 * bytesInOneMB;
  }
  if (supportedImageTypes.includes(fileType)) {
    limit = 5 * bytesInOneMB;
  }
  if (supportedVideoTypes.includes(fileType)) {
    limit = 16 * bytesInOneMB;
  }

  if (unit === "MB") {
    return limit / bytesInOneMB;
  }
  if (unit === "KB") {
    return limit / 1000;
  }

  return limit;
}

function isValidFileSize(file: File): boolean {
  const fileSize = file.size;
  const fileType = file.type;
  const limit = limitByFileType(fileType);
  return fileSize <= limit;
}

export const FileValidationUtils = {
  isValidFileSize: (file: File) => isValidFileSize(file),
  isValidFileType: (file: File): boolean => isValidFileType(file),
  limitByFileType: (fileType: string, unit?: "MB" | "KB") =>
    limitByFileType(fileType, unit),
  supportedFileTypes: supportedFileTypes,
};
