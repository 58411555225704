import React from "react";
import { InputSelectProps, SelectOption } from "../InputSelect";
import AsyncSelect from "react-select/async";

interface InputSelectAsyncProps
  extends Omit<
    InputSelectProps,
    "options" | "defaultValue" | "onCreateOptions"
  > {
  defaultOptions: SelectOption[];
  cacheOptions: boolean;
  loadOptions: (inputValue: string) => Promise<SelectOption[]>;
}

const InputSelectAsync = ({
  defaultOptions,
  isMulti,
  cacheOptions,
  loadOptions,
  value,
  onChange,
  disabled,
  placeholder,
  onMenuClose,
}: InputSelectAsyncProps) => {
  return React.createElement(AsyncSelect, {
    className: "sectionTest",
    styles: {
      control: (styles) => ({
        ...styles,
        minHeight: "36px",
        fontSize: "0.875rem", // chakra-sm
      }),
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    },
    menuPortalTarget: document.body,
    placeholder,
    isDisabled: disabled,
    defaultOptions,
    isMulti,
    cacheOptions,
    loadOptions,
    value,
    onChange,
    closeMenuOnSelect: !isMulti,
    menuPosition: "fixed",
    isClearable: true,
    onMenuClose,
  });
};

export default InputSelectAsync;
