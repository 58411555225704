import axios from "axios";
import { AuthService } from "../services/auth.service";
import { showToast } from "../state/globalSlice";
import { store } from "../state/store";
import { baseURL } from "./base-url";

// TODO: ADD ABSOLUTEURL TO ENV VAR
export const protocol = !!process.env.REACT_APP_REACT_DISABLE_SSL ? "http" : "https";
export const request = axios.create({
  baseURL: `${protocol}://${baseURL}`,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

request.interceptors.request.use(function (config) {
  const accessToken = AuthService.getAccessToken();
  config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : "";
  return config;
});

request.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if ([401].includes(err?.response?.status) && !err?.response?.data?.message?.includes('Usuário')) {
      AuthService.logout();
      store.dispatch(showToast({
        title: 'Sessão expirada',
        status: 'error',
        duration: 3000,
        isClosable: true,
      }));
    } else if (err?.response?.status === 400) {
      store.dispatch(showToast({
        title: err.response.data.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      }));
    } else {
      store.dispatch(showToast({
        title: 'Houve um erro na aplicação, tente novamente mais tarde ou contate o suporte',
        status: 'error',
        duration: 3000,
        isClosable: true,
      }));
    }
    return Promise.reject(err);
  }
);
