import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import { BsFillEmojiSmileFill } from 'react-icons/bs';
import ButtonIcon from '../ButtonIcon';

interface CustomEmojiPickerProps {
  onEmojiSelection: (emoji: string) => void;
  closeAfterPicking?: boolean;
}

const CustomEmojiPicker: React.FC<CustomEmojiPickerProps> = ({ onEmojiSelection, closeAfterPicking = true}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef<HTMLDivElement>(null);
  const emojiIconRef = useRef<HTMLSpanElement>(null);

  const handleOnEmojiClick = (emojiData: EmojiClickData) => {
    onEmojiSelection(emojiData.emoji);
    if (closeAfterPicking) setShowEmojiPicker(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!emojiPickerRef.current?.contains(event.target as Node) &&
        !emojiIconRef.current?.contains(event.target as Node)) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <span ref={emojiIconRef}>
        <ButtonIcon
          icon={<BsFillEmojiSmileFill />}
          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
        />
      </span>
      {showEmojiPicker && (
        <Box position="absolute" zIndex={1} top="20px" ref={emojiPickerRef}>
          <EmojiPicker onEmojiClick={handleOnEmojiClick} />
        </Box>
      )}
    </>
  );
};

export default CustomEmojiPicker;
