const wdayToDay: Record<string, string> = {
  "0": "dom",
  "1": "seg",
  "2": "ter",
  "3": "qua",
  "4": "qui",
  "5": "sex",
  "6": "sáb",
};

interface LabeledCronExpression {
  frequency: "diário" | "semanal" | "mensal" | null;
  daysOfMonth: string | null;
  daysOfWeek: string | null;
  hours: string | null;
  minutes: string | null;
}

function getLabeledValuesFromCronExpression(expression: string | null): LabeledCronExpression | null {
  if (!expression) {
    return null;
  }
  const [minutes, hours, dayOfMonth, _month, dayOfWeek] = expression.split(" ");

  const labels: LabeledCronExpression = {
    frequency: null,
    daysOfMonth: null,
    daysOfWeek: null,
    hours: null,
    minutes: null,
  };

  if (dayOfWeek !== "*") {
    labels.frequency = "semanal";
    labels.daysOfWeek = dayOfWeek.split(",").map((day) => wdayToDay[day]).join(",");
  }
  if (dayOfMonth !== "*") {
    labels.frequency = "mensal";
    labels.daysOfMonth = dayOfMonth;
  }
  if (dayOfWeek === "*" && dayOfMonth === "*") {
    labels.frequency = "diário";
  }

  labels.hours = hours.padStart(2, "0");
  labels.minutes = minutes.padStart (2, "0");

  return labels;
}

export const CronUtils = {
  getLabeledValuesFromCronExpression,
};
