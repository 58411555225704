import {
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import InputCheckboxGroupControlled from "../../../../components/InputCheckboxGroupControlled";
import InputSwitchControlled from "../../../../components/InputSwitchControlled";
import TableFilters from "../../../../components/TableFilters";
import WhatsappTemplatePreview from "../../../../components/WhatsappTemplatePreview";
import { apiRoutes } from "../../../../constants/api-routes";
import { appPaths } from "../../../../constants/app-paths";
import { colors } from "../../../../constants/colors";
import {
  AutomationsService,
  CreateAutomationDto,
} from "../../../../services/automations.service";
import { FiltersService } from "../../../../services/filters.service";
import { MessageTemplatesService } from "../../../../services/message-templates.service";
import { MessageTemplate } from "../../../../types/MessageTemplate";
import { CustomerFilterUtils } from "../../../../utils/customer-filter.utils";
import { MessageTemplateUtils } from "../../../../utils/message-templates.utils";

const monthDayOptions = Array.from({ length: 31 }, (_, i) => String(i + 1)).map(
  (day) => ({
    label: day,
    value: day,
  })
);

const wdayOptions: {
  label: string;
  value: string;
}[] = [
  {
    label: "Segunda-feira",
    value: "1",
  },
  {
    label: "Terça-feira",
    value: "2",
  },
  {
    label: "Quarta-feira",
    value: "3",
  },
  {
    label: "Quinta-feira",
    value: "4",
  },
  {
    label: "Sexta-feira",
    value: "5",
  },
  {
    label: "Sábado",
    value: "6",
  },
  {
    label: "Domingo",
    value: "0",
  },
];

const timeOptions = [
  480, 510, 540, 570, 600, 630, 660, 690, 720, 750, 780, 810, 840, 870, 900,
  930, 960, 990, 1020, 1050, 1080, 1110, 1140, 1170, 1200, 1230, 1260,
];

const schema = yup
  .object({
    name: yup.string().required(),
    isActive: yup.boolean().required(),
    messageTemplateId: yup.string().required(),
    filterId: yup.string().required(),
    monthlyMessageLimitOnWhatsapp: yup.number().required().positive().integer(),
    frequency: yup.string().oneOf(["daily", "weekly", "monthly"]),
    minutesOfTheDay: yup.string().required(),
    daysOfWeek: yup.array().when("frequency", {
      is: "weekly",
      then: yup.array().min(1).required(),
    }),
    daysOfMonth: yup.array().when("frequency", {
      is: "monthly",
      then: yup.array().min(1).required(),
    }),
    isAutomationRepetitionAllowed: yup.boolean().required(),
    minDaysSinceLastCampaign: yup.number().transform(
      (value, originalValue) => (originalValue.trim() === '' ? null : value)
    ).required("Este é um campo obrigatório").positive().integer(),
  })
  .required();

const CreateBackgroundAutomationPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: null,
      isActive: true,
      messageTemplateId: null,
      filterId: null,
      monthlyMessageLimitOnWhatsapp: 0,
      frequency: "daily",
      minutesOfTheDay: null,
      daysOfWeek: [],
      daysOfMonth: [],
      isAutomationRepetitionAllowed: false,
      minDaysSinceLastCampaign: 0,
    },
  });
  const navigate = useNavigate();
  const toast = useToast();
  const watchMessageTemplateId = watch("messageTemplateId");
  const watchFrequency = watch("frequency");
  const watchFilterId = watch("filterId");
  const [templateArgs, setTemplateArgs] = useState<{
    [key: string]: string | undefined;
  }>({});

  const createAutomation = useMutation(
    (createAutomationDto: CreateAutomationDto) =>
      AutomationsService.createAutomation(createAutomationDto),
    {
      onSuccess: (res) => {
        toast({
          title: "Automação criada com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate(appPaths.automations.backgroundAutomations.index());
      }
    }
  );
  const { data: filters } = useQuery(apiRoutes.listFilters(), async () => {
    const { data } = await FiltersService.listFilters();
    return data;
  });
  const { data: templates } = useQuery(
    apiRoutes.listMessageTemplates(),
    async () => {
      const { data } = await MessageTemplatesService.listMessageTemplates();
      return data;
    },
    {
      select(data) {
        return data.filter(
          (template: MessageTemplate) => template.status === "approved"
        );
      },
    }
  );
  useEffect(() => {
    if (watchFrequency !== "weekly") {
      setValue("daysOfWeek", []);
    }
    if (watchFrequency !== "monthly") {
      setValue("daysOfMonth", []);
    }
  }, [setValue, watchFrequency]);

  function handleChangeTemplateParameter(parameter: string, value: string) {
    setTemplateArgs({
      ...templateArgs,
      [parameter]: value,
    });
  }

  async function onSubmit(data: any) {
    const {
      name,
      isActive,
      messageTemplateId,
      filterId,
      monthlyMessageLimitOnWhatsapp,
      frequency,
      minutesOfTheDay,
      daysOfWeek,
      daysOfMonth,
      isAutomationRepetitionAllowed,
      minDaysSinceLastCampaign,
    } = data;
    const hours = Math.floor(Number(minutesOfTheDay) / 60);
    const minutes = Number(minutesOfTheDay) % 60;

    await createAutomation.mutateAsync({
      name,
      daysOfMonth: daysOfMonth.filter(Boolean).map(Number),
      daysOfWeek: daysOfWeek.filter(Boolean).map(Number),
      filterId,
      frequency,
      isActive,
      messageTemplateId,
      monthlyMessageLimitOnWhatsapp,
      hours,
      minutes,
      templateArgs,
      isAutomationRepetitionAllowed,
      minDaysSinceLastCampaign,
    });
  }

  const selectedTemplate = watchMessageTemplateId
    ? templates?.find((template) => template.id === watchMessageTemplateId)
    : null;
  const selectedFilter = watchFilterId
    ? filters?.find((filter) => filter.id === watchFilterId)
    : null;

  const customParameters = MessageTemplateUtils.getCustomParametersInText(
    selectedTemplate?.templateText || ""
  );

  return (
    <Grid
      height="auto"
      templateColumns="2fr 1fr"
      paddingTop="50px"
      paddingBottom="50px"
      paddingX="100px"
      alignItems="start"
      gap={4}>
      <GridItem>
        <Heading size="md" mb={5}>
          Nova automação
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={5}>
            <FormControl isRequired>
              <FormLabel>Nome da automação</FormLabel>
              <Input placeholder="Nome" {...register("name")} />
              <Text color={colors.danger} fontSize="xs">
                {errors.name?.message}
              </Text>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Template para envio</FormLabel>
              <Select
                placeholder="Selecione um template"
                {...register("messageTemplateId")}>
                {templates?.map((template) => (
                  <option
                    key={template.id}
                    value={template.id}
                    title={template.templateText}>
                    {template.name}
                  </option>
                ))}
              </Select>
              <Text color={colors.danger} fontSize="xs">
                {errors.messageTemplateId?.message}
              </Text>
            </FormControl>
            {selectedTemplate && (
              <Container paddingX={"50px"}>
                {customParameters.map((param) => (
                  <FormControl isRequired key={param}>
                    <FormLabel fontSize="sm">
                      {param.replaceAll("[", "").replaceAll("]", "")}
                    </FormLabel>
                    <Input
                      size="sm"
                      onChange={(e) =>
                        handleChangeTemplateParameter(param, e.target.value)
                      }
                      value={templateArgs[param] || ""}
                      required
                    />
                  </FormControl>
                ))}
              </Container>
            )}
            <FormControl isRequired>
              <FormLabel>Filtro a ser utilizado</FormLabel>
              <Select
                placeholder="Selecione um filtro"
                {...register("filterId")}>
                {filters?.map((filter) => (
                  <option
                    key={filter.id}
                    value={filter.id}
                    title={CustomerFilterUtils.parseFilterCriteria(filter.criteria)
                      .map((criteria) => `${criteria.title}: ${criteria.value}`)
                      .join("\n")}>
                    {filter.name} -{" "}
                    {CustomerFilterUtils.parseFilterCriteria(filter.criteria)
                      .map((criteria) => `${criteria.title}: ${criteria.value}`)
                      .join(", ")}
                  </option>
                ))}
              </Select>
              <Text color={colors.danger} fontSize="xs">
                {errors.filterId?.message}
              </Text>
              {selectedFilter && (
                <TableFilters
                  filterCriteria={selectedFilter.criteria || null}
                />
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Frequência de envio</FormLabel>
              <Stack spacing={4}>
                <Select
                  placeholder="Selecione a frequência"
                  {...register("frequency")}>
                  <option value="daily">Diário</option>
                  <option value="weekly">Semanal</option>
                  <option value="monthly">Mensal</option>
                </Select>
                {watchFrequency === "weekly" && (
                  <Flex
                    flexDir={"column"}
                    flexWrap={"wrap"}
                    gap={2}
                    height="100px">
                    <InputCheckboxGroupControlled
                      options={wdayOptions}
                      name="daysOfWeek"
                      control={control}
                    />
                  </Flex>
                )}
                {watchFrequency === "monthly" && (
                  <Flex
                    flexDir={"row"}
                    flexWrap={"wrap"}
                    rowGap={"20px"}
                    columnGap={"50px"}>
                    <InputCheckboxGroupControlled
                      options={monthDayOptions}
                      name="daysOfMonth"
                      control={control}
                      itemWidth="20px"
                    />
                  </Flex>
                )}
                {!!watchFrequency && (
                  <Select
                    placeholder="Selecione a hora"
                    {...register("minutesOfTheDay")}>
                    {timeOptions.map((minutesOfTheDay) => {
                      const hour = Math.floor(minutesOfTheDay / 60);
                      const minutes = minutesOfTheDay % 60;
                      return (
                        <option key={minutesOfTheDay} value={minutesOfTheDay}>
                          {`${String(hour).padStart(2, "0")}:${String(
                            minutes
                          ).padStart(2, "0")}`}
                        </option>
                      );
                    })}
                  </Select>
                )}
              </Stack>
              <Text color={colors.danger} fontSize="xs">
                {errors.minutesOfTheDay?.message}
              </Text>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Limite mensal de envio</FormLabel>
              <Input
                type="number"
                placeholder="Limite mensal"
                {...register("monthlyMessageLimitOnWhatsapp")}
              />
              <Text color={colors.danger} fontSize="xs">
                {errors.monthlyMessageLimitOnWhatsapp?.message}
              </Text>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Ocultar clientes que receberam uma campanhas nos últimos</FormLabel>
                <InputGroup>
                  <Input
                    type="number"
                    placeholder="Dias"
                    {...register("minDaysSinceLastCampaign")}
                  />
                  <InputRightAddon>dias</InputRightAddon>
                </InputGroup>
              <Text color={colors.danger} fontSize="xs">
                {errors.minDaysSinceLastCampaign?.message}
              </Text>
            </FormControl>
            <FormControl>
              <FormLabel>Cliente pode receber mais de uma vez a mesma automação</FormLabel>
              <InputSwitchControlled name="isAutomationRepetitionAllowed" control={control} />
              <Text color={colors.danger} fontSize="xs">
                {errors.isAutomationRepetitionAllowed?.message}
              </Text>
            </FormControl>
            <FormControl>
              <FormLabel>Ativo?</FormLabel>
              <InputSwitchControlled name="isActive" control={control} />
              <Text color={colors.danger} fontSize="xs">
                {errors.isActive?.message}
              </Text>
            </FormControl>
            <Divider />
            <Flex justify={"flex-end"}>
              <Button
                width="30%"
                isLoading={false}
                color={colors.white}
                bgColor={colors.primary}
                type="submit">
                Salvar
              </Button>
            </Flex>
          </Stack>
        </form>
      </GridItem>
      <GridItem position={"sticky"} top={0} alignSelf="start">
        {selectedTemplate && (
          <WhatsappTemplatePreview
            message={selectedTemplate.templateText}
            footer={selectedTemplate.footerText}
            buttons={selectedTemplate.messageTemplateButtons}
            fileUrl={selectedTemplate.mediaUrl}
          />
        )}
      </GridItem>
    </Grid>
  );
};

export default CreateBackgroundAutomationPage;
