import { Flex } from "@chakra-ui/react";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { colors } from "../../../../constants/colors";
import { ListConversationDetailedItem } from "../../../../types/Conversation";
import ConversationItem from "../ConversationItem";
import { User } from "../../../../types/Prisma";

interface ListConversationsProps {
  conversations: ListConversationDetailedItem[];
  onScroll: () => void;
  listConversationRef: React.RefObject<HTMLDivElement>;
  footerComponent?: React.ReactNode | null;
  toggleListConversations: () => void;
  companyAgents: User[] | undefined;
}

const ListConversations = ({ conversations, listConversationRef, onScroll, footerComponent, toggleListConversations, companyAgents }: ListConversationsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  function handleClickConversation(conversationId: string) {
    searchParams.set("conversationId", conversationId);
    setSearchParams(searchParams);
    toggleListConversations()
  }

  return (
    <Flex
      flexDir={'column'}
      padding={3}
      borderWidth={"1px"}
      borderStyle="solid"
      height='100%'
      flex={1}
      borderColor={colors.lightGrey}
      overflowY={"auto"}
      onScroll={onScroll}
      ref={listConversationRef}
    >
      {conversations.map((conversation) => (
        <ConversationItem
          key={conversation.id}
          conversationId={conversation.id}
          name={conversation.recipientName}
          message={conversation.lastMessage}
          isActive={conversation.id === searchParams.get("conversationId")}
          onClick={() => handleClickConversation(conversation.id)}
          companyAgents={companyAgents}
        />
      ))}
      {footerComponent}
    </Flex>
  );
};

export default ListConversations;
