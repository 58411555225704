import { QuestionIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Flex,
  Skeleton,
  Stat,
  StatLabel,
  StatNumber,
  Tooltip,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { colors } from "../../constants/colors";

export interface CardStatisticProp {
  title: string;
  value?: string | number;
  icon: ReactNode;
  isLoading?: boolean;
  bgIconColor?: string;
  tooltip?: string;
  size?: "sm" | "md" | "lg";
}

const CardStatistic = ({
  title,
  value,
  icon,
  isLoading,
  bgIconColor,
  tooltip,
  size = "md"
}: CardStatisticProp) => {
  const sizeMap = {
    sm: {
      iconSize: "14px",
      labelFontSize: "14px",
      valueFontSize: "18px",
    },
    md: {
      iconSize: "16px",
      labelFontSize: "16px",
      valueFontSize: "24px",
    },
    lg: {
      iconSize: "20px",
      labelFontSize: "20px",
      valueFontSize: "20px",
    },
  };

  return (
    <Box
      display="flex"
      flex={1}
      alignItems="center"
      boxShadow="lg"
      p="6"
      rounded="3xl"
      bg="white"
      gap={2}
    >
      <Center
        marginLeft={"20px"}
        color={colors.white}
        borderRadius="50%"
        bgColor={bgIconColor || colors.primary}
        height="40px"
        width="40px"
        fontSize={sizeMap[size].iconSize}>
        {icon}
      </Center>
      <Stat display={"flex"} justifyContent="center">
        <Flex alignItems={'center'} gap={3}>
          <StatLabel color={colors.fontlightGrey} textTransform="uppercase" fontSize={sizeMap[size].labelFontSize}>
            {title}
          </StatLabel>
          {tooltip && (
            <Tooltip label={tooltip} placement='top-start'>
              <QuestionIcon boxSize={3} />
            </Tooltip>
          )}
        </Flex>
        {isLoading ? (
          <Skeleton width="140px" height="1.5rem" />
        ) : (
          <StatNumber fontSize={sizeMap[size].valueFontSize}>{value || '-'}</StatNumber>
        )}
      </Stat>
    </Box>
  );
};

export default CardStatistic;
