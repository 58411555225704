import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaRegUser } from "react-icons/fa";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { apiRoutes } from "../../constants/api-routes";
import { appPaths } from "../../constants/app-paths";
import { StatisticsService } from "../../services/statistics.service";
import { RFMGroupsEnum } from "../../types/RFMGroupsEnum";
import { RFMStats } from "../../types/RFMSummary";
import { MoneyUtils } from "../../utils/money.utils";

const CustomGridItem = ({
  area,
  bgColor,
  rfmGroup,
  stats,
  iconColor,
  totalCustomerCount,
}: {
  area: string;
  bgColor: string;
  totalCustomerCount: number;
  rfmGroup: RFMGroupsEnum;
  stats: RFMStats;
  iconColor: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const customerPercentage = (
    (stats.customerCount / totalCustomerCount) *
    100
  ).toFixed(2);

  function handleClickArea() {
    navigate(`${appPaths.customers.index()}?${stats.filterCriteria}`);
  }
  return (
    <GridItem
      gridArea={area}
      bgColor={bgColor}
      p={3}
      style={{ transition: "all 0.2s ease-in-out" }}
      _hover={{
        transform: "scale(1.05)",
        cursor: "pointer",
      }}
      onClick={handleClickArea}>
      <Tooltip
        hasArrow
        placement="right-start"
        label={
          <Box>
            <Text fontWeight={"bold"}>{t(`enums.RFMGroup.${rfmGroup}`)}</Text>
            <hr />
            <Text>
              Clientes: {stats.customerCount} ({customerPercentage}%)
            </Text>
            <Text>Recência média: {stats.avgRecency} dias</Text>
            <Text>Frequência média: {stats.avgFrequency} compras</Text>
            <Text>
              Gasto médio: {MoneyUtils.formatCurrency(stats.avgMonetary)}
            </Text>
          </Box>
        }
        whiteSpace="pre-line">
        <Flex
          flexDir="column"
          alignItems={"flex-end"}
          width="100%"
          height="100%">
          <Text>{t(`enums.RFMGroup.${rfmGroup}`)}</Text>
          <Flex alignItems={"center"} gap={2} fontWeight="bold">
            <FaRegUser color={iconColor} />
            {stats.customerCount}
          </Flex>
          {stats.customerCount > 0 && <Text>{customerPercentage}%</Text>}
        </Flex>
      </Tooltip>
    </GridItem>
  );
};

const RFMChart = () => {
  const { data, isFetching } = useQuery(
    apiRoutes.getRFMAnalysis(),
    async () => {
      const { data } = await StatisticsService.getRFMAnalysis();
      return data;
    }
  );
  const totalCustomerCount = Object.values(data || {}).reduce(
    (acc, curr) => acc + (curr.customerCount || 0),
    0
  );

  const gridItems = data ? [
    {
      bgColor: "#C8E6C8",
      iconColor: "#368855",
      rfmGroup: RFMGroupsEnum.CHAMPIONS,
    },
    {
      bgColor: "#E7F5E9",
      iconColor: "#28c376",
      rfmGroup: RFMGroupsEnum.LOYAL_CUSTOMERS,
    },
    {
      bgColor: "#B2E5FB",
      iconColor: "#1fa9e8",
      rfmGroup: RFMGroupsEnum.POTENTIAL_LOYALISTS,
    },
    {
      bgColor: "#FFCCBC",
      iconColor: "#f17652",
      rfmGroup: RFMGroupsEnum.RECENT_CUSTOMERS,
    },
    {
      bgColor: "#E3F2FD",
      iconColor: "#77bdf7",
      rfmGroup: RFMGroupsEnum.PROMISING,
    },
    {
      bgColor: "#FFFCCD",
      iconColor: "#ddb425",
      rfmGroup: RFMGroupsEnum.NEED_ATTENTION,
    },
    {
      bgColor: "#C4E2F8",
      iconColor: "#4a83a8",
      rfmGroup: RFMGroupsEnum.ABOUT_TO_SLEEP,
    },
    {
      bgColor: "#FFCDD2",
      iconColor: "#e72726",
      rfmGroup: RFMGroupsEnum.CANNOT_LOSE_THEM,
    },
    {
      bgColor: "#FFE5BD",
      iconColor: "#c95815",
      rfmGroup: RFMGroupsEnum.AT_RISK,
    },
    {
      bgColor: "#EFF0F3",
      iconColor: "#939dad",
      rfmGroup: RFMGroupsEnum.HIBERNATING,
    },
    {
      bgColor: "#7c7b7b",
      iconColor: "#c2c2c2",
      rfmGroup: RFMGroupsEnum.LOST_CUSTOMERS,
    },
  ].map((gridOption) => ({
    ...gridOption,
    stats: data[gridOption.rfmGroup],
    totalCustomerCount,
    area: gridOption.rfmGroup,
  })) : [];

  return isFetching ? (
    <Center width="100%" height="100%">
      <Spinner />
    </Center>
  ) : (
    <Grid
      width={"100%"}
      height={"100%"}
      templateColumns={"repeat(5, 1fr)"}
      templateRows={"repeat(5, 1fr)"}
      templateAreas={`
        "${RFMGroupsEnum.CANNOT_LOSE_THEM} ${RFMGroupsEnum.CANNOT_LOSE_THEM} ${RFMGroupsEnum.LOYAL_CUSTOMERS} ${RFMGroupsEnum.LOYAL_CUSTOMERS}  ${RFMGroupsEnum.CHAMPIONS}"
        "${RFMGroupsEnum.AT_RISK} ${RFMGroupsEnum.AT_RISK} ${RFMGroupsEnum.LOYAL_CUSTOMERS} ${RFMGroupsEnum.LOYAL_CUSTOMERS}  ${RFMGroupsEnum.CHAMPIONS}"
        "${RFMGroupsEnum.AT_RISK} ${RFMGroupsEnum.AT_RISK} ${RFMGroupsEnum.NEED_ATTENTION} ${RFMGroupsEnum.POTENTIAL_LOYALISTS}  ${RFMGroupsEnum.POTENTIAL_LOYALISTS}"
        "${RFMGroupsEnum.LOST_CUSTOMERS} ${RFMGroupsEnum.HIBERNATING} ${RFMGroupsEnum.ABOUT_TO_SLEEP} ${RFMGroupsEnum.POTENTIAL_LOYALISTS}  ${RFMGroupsEnum.POTENTIAL_LOYALISTS}"
        "${RFMGroupsEnum.LOST_CUSTOMERS} ${RFMGroupsEnum.HIBERNATING} ${RFMGroupsEnum.ABOUT_TO_SLEEP} ${RFMGroupsEnum.PROMISING}  ${RFMGroupsEnum.RECENT_CUSTOMERS}"
      `}>
      {gridItems.map(
        ({ area, bgColor, iconColor, rfmGroup, stats }) => (
          <CustomGridItem
            area={area}
            totalCustomerCount={totalCustomerCount}
            bgColor={bgColor}
            iconColor={iconColor}
            rfmGroup={rfmGroup}
            stats={stats}
          />
        )
      )}
    </Grid>
  );
};

export default RFMChart;
