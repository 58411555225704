import { QuestionIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, Select, Text, Tooltip } from "@chakra-ui/react";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { AiOutlineAreaChart } from "react-icons/ai";
import { BsFillEyeFill, BsPeopleFill } from "react-icons/bs";
import { FaBox, FaFunnelDollar } from "react-icons/fa";
import { GiPayMoney } from "react-icons/gi";
import { HiCursorClick } from "react-icons/hi";
import { MdAttachMoney } from "react-icons/md";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import BreadcrumbBase from "../../../../components/BreadcrumbBase";
import CardStatistic from "../../../../components/CardStatistic";
import CustomECharts from "../../../../components/CustomECharts";
import { apiRoutes } from "../../../../constants/api-routes";
import { appPaths } from "../../../../constants/app-paths";
import { colors } from "../../../../constants/colors";
import { AutomationsService } from "../../../../services/automations.service";
import { StatisticsService } from "../../../../services/statistics.service";
import { ChartUtils, FunnelMetric } from "../../../../utils/chart.utils";
import { MoneyUtils } from "../../../../utils/money.utils";
import { NumberUtils } from "../../../../utils/number.utils";

const BackgroundAutomationDetailsPage = () => {
  const { automationId } = useParams();
  const [startDate, setStartDate] = useState<Date>(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState<Date>(endOfMonth(new Date()));
  const [funnelMetric, setFunnelMetric] =
    useState<FunnelMetric>("totalOrdersValue");

  const { data: automation } = useQuery(
    apiRoutes.showAutomation(automationId!),
    async () => {
      const { data } = await AutomationsService.showAutomation(automationId!);
      return data;
    }
  );
  const { data: automationSalesData } = useQuery(
    [
      "statistics-service",
      "automation-sales",
      automationId,
      startDate,
      endDate,
    ],
    async () => {
      const { data } = await StatisticsService.getChartAutomationSales(
        automationId || "",
        startDate,
        endDate
      );
      return data;
    },
    {}
  );

  const { data: automationPerformanceData } = useQuery(
    [
      "statistics-service",
      "automation-performance",
      automationId,
      startDate,
      endDate,
    ],
    async () => {
      const { data } = await StatisticsService.getChartAutomationPerformance(
        automationId || "",
        startDate,
        endDate
      );
      return data;
    },
    {}
  );

  return (
    <Box>
      <Box paddingX={"100px"} paddingY="20px">
        <BreadcrumbBase
          items={[
            {
              path: appPaths.automations.backgroundAutomations.index(),
              name: "Automações",
            },
            {
              path: appPaths.automations.backgroundAutomations.details(
                automationId || ""
              ),
              name: automation?.name || "",
            },
          ]}
        />
        <Flex gap={10} alignItems="center" justifyContent={"flex-end"}>
          <Flex gap={2} alignItems="center">
            <Text>Início</Text>
            <ReactDatePicker
              isClearable
              selected={startDate}
              onSelect={(date: Date) => setStartDate(date)}
              onChange={(date: Date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
            />
          </Flex>
          <Flex gap={2} alignItems="center">
            <Text>Fim</Text>
            <ReactDatePicker
              isClearable
              selected={endDate}
              onSelect={(date: Date) => setEndDate(date)}
              onChange={(date: Date) => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
            />
          </Flex>
        </Flex>
        <Heading>Conversões no período</Heading>
        <CustomECharts
          chartWidth={"100%"}
          chartHeight="450px"
          marginTop={"50px"}
          option={{
            legend: {},
            dataZoom: [
              {
                type: "slider",
                start: 0,
                end: 50,
              },
            ],
            tooltip: {},
            color: [colors.primaryLight, colors.green],
            dataset: {
              dimensions: ["datetime", "messages_count", "orders_count"],
              source: automationPerformanceData || [],
            },
            xAxis: {
              type: "category",
            },
            yAxis: [
              {
                name: "Mensagens",
                nameLocation: "middle",
                splitLine: { show: false },
                nameGap: 55,
              },
            ],
            series: [
              { type: "line", name: "Mensagens enviadas", yAxisIndex: 0 },
              { type: "line", name: "Mensagens convertidas em venda", yAxisIndex: 0 },
            ],
          }}
        />
        <Flex justifyContent="space-between" gap={3} mt={5}>
          {[
            {
              icon: <BsPeopleFill />,
              title: "Disparos",
              value: automationSalesData?.totalMessagesSent,
              bgIconColor: colors.secondary,
              tooltip: "Quantidade de Disparos",
            },
            {
              icon: <BsFillEyeFill />,
              title: "Lidas",
              value: automationSalesData?.read.count,
              bgIconColor: colors.primaryLight,
              tooltip: "Quantidade de mensagens lidas",
            },
            {
              icon: <HiCursorClick />,
              title: "Cliques",
              value: automationSalesData?.engaged.count,
              bgIconColor: colors.green,
              tooltip: "Quantidade de cliques no link",
            },
            {
              icon: <FaBox />,
              title: "Pedidos",
              value: automationSalesData?.received.totalOrders,
              bgIconColor: colors.blueTurquose,
              tooltip: "Quantidade de conversões",
            },
            {
              icon: <FaFunnelDollar />,
              title: "Conversão",
              value: NumberUtils.getPercentValue(
                automationSalesData?.received.totalOrders,
                automationSalesData?.received.count
              ),
              bgIconColor: colors.red,
              tooltip:
                "Percentual de mensagens entregues converteram em vendas",
            },
          ].map(({ icon, title, value, bgIconColor, tooltip }) => (
            <CardStatistic
              value={value}
              key={title}
              icon={icon}
              title={title}
              bgIconColor={bgIconColor}
              tooltip={tooltip}
            />
          ))}
        </Flex>
        <Heading marginTop={"50px"}>
          Performance da automação{" "}
          <Tooltip
            label={
              "Estimativa considerando compras realizadas até 3 dias após o envio da mensagem"
            }>
            <QuestionIcon boxSize={3} />
          </Tooltip>
        </Heading>
        <Heading size="xs" color={colors.fontlightGrey}>
          {startDate &&
            endDate &&
            `${format(startDate, "dd/MM/yyyy")} à ${format(
              endDate,
              "dd/MM/yyyy"
            )}`}
        </Heading>
        <CustomECharts
          chartWidth={"100%"}
          chartHeight="450px"
          topRightComponent={
            <Select
              defaultValue={funnelMetric}
              onChange={(e) => setFunnelMetric(e.target.value as FunnelMetric)}>
              <option value="totalOrdersValue">Total em vendas</option>
              <option value="totalOrders">Total de pedidos</option>
              <option value="roi">ROI</option>
            </Select>
          }
          option={{
            title: {
              text: "ENGAJOU = CLICOU OU RESPONDEU",
              borderColor: "#d0d0d0",
              borderWidth: 1,
              textStyle: {
                fontSize: 10,
                color: "#d0d0d0",
              },
              left: "80%",
              top: "90%",
            },
            color: [colors.orange, colors.primaryLight, colors.green],
            series: [
              {
                type: "funnel",
                name: "Outer",
                width: "80%",
                label: {
                  show: true,
                  fontSize: 14,
                },
                labelLine: {
                  length: 15,
                  lineStyle: {
                    width: 2,
                    type: "solid",
                  },
                },
                itemStyle: {
                  borderColor: "#fff",
                  borderWidth: 2,
                },
                emphasis: {
                  disabled: true,
                },
                data: [
                  {
                    value: automationSalesData?.received[funnelMetric],
                    name: `RECEBEU A\nMENSAGEM (${automationSalesData?.received.count})`,
                  },
                  {
                    value: automationSalesData?.read[funnelMetric],
                    name: `LEU A MENSAGEM (${automationSalesData?.read.count})`,
                  },
                  {
                    value: automationSalesData?.engaged[funnelMetric],
                    name: `ENGAJOU COM A MENSAGEM (${automationSalesData?.engaged.count})`,
                  },
                ],
              },
              {
                type: "funnel",
                name: "Inner",
                width: "80%",
                label: {
                  show: true,
                  position: "inside",
                  fontSize: 14,
                },
                labelLine: {
                  length: 20,
                  lineStyle: {
                    width: 2,
                    type: "solid",
                  },
                },
                itemStyle: {
                  borderColor: "#fff",
                  borderWidth: 2,
                },
                emphasis: {
                  label: {
                    fontSize: 20,
                  },
                },
                data: [
                  {
                    value: automationSalesData?.received[funnelMetric],
                    name: ChartUtils.getFormattedFunnelValue(
                      automationSalesData?.received[funnelMetric],
                      funnelMetric
                    ),
                  },
                  {
                    value: automationSalesData?.read[funnelMetric],
                    name: ` ${ChartUtils.getFormattedFunnelValue(
                      automationSalesData?.read[funnelMetric],
                      funnelMetric
                    )} `, // blank space added to have different color on chart
                  },
                  {
                    value: automationSalesData?.engaged[funnelMetric],
                    name: `  ${ChartUtils.getFormattedFunnelValue(
                      automationSalesData?.engaged[funnelMetric],
                      funnelMetric
                    )}  `, // blank space added to have different color on chart
                  },
                ],
              },
            ],
          }}
        />
        <Flex justifyContent="space-between" gap={3} mt={5}>
          {[
            {
              icon: <MdAttachMoney />,
              title: "Receita gerada",
              value: ChartUtils.getFormattedFunnelValue(
                automationSalesData?.received.totalOrdersValue,
                "totalOrdersValue"
              ),
              bgIconColor: colors.greenMedium,
              tooltip:
                "Receita gerada por vendas de Clientes que Receberam a Mensagem",
            },
            {
              icon: <GiPayMoney />,
              title: "Custo dos disparos",
              value: MoneyUtils.formatCurrency(
                automationSalesData?.automationCost || 0
              ),
              bgIconColor: colors.danger,
              tooltip: "Total de Mensagens Entregues * Custo por Mensagem",
            },
            {
              icon: <AiOutlineAreaChart />,
              title: "ROI",
              value: ChartUtils.getFormattedFunnelValue(
                automationSalesData?.received.roi,
                "roi"
              ),
              bgIconColor: colors.primaryMedium,
              tooltip:
                "Receita Gerada / (Total de Mensagens Entregues * Custo por Mensagem)",
            },
          ].map(({ icon, title, value, bgIconColor, tooltip }) => (
            <CardStatistic
              value={value}
              key={title}
              icon={icon}
              title={title}
              bgIconColor={bgIconColor}
              tooltip={tooltip}
            />
          ))}
        </Flex>
      </Box>
    </Box>
  );
};

export default BackgroundAutomationDetailsPage;
