export const colors = {
  white: "white",
  background: "#FFFFFF",
  primaryMedium: "#0726d9",
  primary: "#001594",
  primaryLight: "#4c65f1",
  secondary: '#ffbe1b',
  green: "#00ff6a",
  greenMedium: "#018f3c",
  blueTurquose: "#0afcea",
  blueLight: "#e0e5fa",
  danger: "#ff6567",
  red: "red",
  black: "black",
  darkGrey: "#6E6E6E",
  lightGrey: "#F1F1F1",
  middleGrey: "#c0c0c0",
  fontDark: "#1F1F1F",
  fontLight: "white",
  fontlightGrey: "#6E6E6E",
  orange: 'orange',
  hyperlink: '#007bff',
  purple: '#805AD5',
  status: {
    inProgress: "yellow",
    completed: "green",
    scheduled: "purple",
    failed: "red",
    canceled: "gray",
    interrupted: 'red',
    rejected: 'red',
    approved: 'green',
    pending: 'yellow',
    disabled: 'gray',
    paused: 'gray',
    deleted: 'red',
  }
};
