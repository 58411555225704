import { useToast } from "@chakra-ui/react";
import { GupshupTemplateType } from "../types/GupshupTemplateType";
import { FileValidationUtils } from "../utils/file-validation.utils";

interface UseFileValidationReturn {
  validateFile: (file: File) => boolean;
  getAcceptedFileTypes: (templateType: GupshupTemplateType) => string;
}

const useFileValidation = (): UseFileValidationReturn => {
  const toast = useToast();

  function getAcceptedFileTypes(templateType: GupshupTemplateType): string {
    if (GupshupTemplateType.DOCUMENT === templateType) {
      return "application/pdf";
    }
    if (GupshupTemplateType.IMAGE === templateType) {
      return "image/jpeg, image/png";
    }
    if (GupshupTemplateType.VIDEO === templateType) {
      return "video/mp4, video/3gp";
    }
    if (GupshupTemplateType.AUDIO === templateType) {
      return "audio/ogg, audio/x-mpeg-3, audio/mpeg-3";
    }
    return "";
  }

  function validateFile(file: File) {
    if (!file) {
      return false;
    }
    
    if (!FileValidationUtils.isValidFileSize(file)) {
      toast({
        title: "Arquivo muito grande",
        description: `O arquivo deve ter no máximo ${FileValidationUtils.limitByFileType(
          file.type,
          "MB"
        )} MB`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      return false;
    }

    return true;
  }

  return {
    validateFile,
    getAcceptedFileTypes,
  };
};

export default useFileValidation;
