import { Avatar, Flex, IconButton, Text, useToast } from "@chakra-ui/react";
import { BiSolidCategory } from "react-icons/bi";
import { BsPeopleFill, BsPersonCheckFill } from "react-icons/bs";
import { FaInbox, FaChartBar, FaSearch } from "react-icons/fa";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConversationTabsEnum } from "../..";
import Sider from "../../../../components/Sider";
import { apiRoutes } from "../../../../constants/api-routes";
import { appPaths } from "../../../../constants/app-paths";
import { ConversationCategoriesService } from "../../../../services/conversation-categories.service";
import { ConversationsService } from "../../../../services/conversations.service";
import {
  changeActiveTab,
  getTotalOpenConversationByCategoryId,
  setConversationCategories,
  setConversationIdToCategoryIdMap,
  setConversationsIdsByAgentId,
  setHasNoAgentConversationIds,
  setOpenConversationIds,
  setUnreadConverstionIds,
} from "../../../../state/inboxSlice";
import { AppDispatch, RootState } from "../../../../state/store";
import {
  ConversationCategory,
  ConversationCategoryDetailed,
} from "../../../../types/ConversationCategory";
import PopoverNewConversation from "./PopoverNewConversation";
import PopoverNewConversationCategory from "./PopoverNewConversationCategory";
import { MdPersonOff } from "react-icons/md";
import { User } from "../../../../types/Prisma";

interface SideNavigationProps {
  width?: string;
  companyAgents: User[] | undefined;
}

const SideNavigation = ({ width, companyAgents }: SideNavigationProps) => {
  const {
    activeTab,
    conversationCategories,
    openConversationIds,
    unreadConversationIds,
    daysSinceLastMessage,
    conversationCategoryId,
    hasNoAgentConversationIds,
    conversationsIdsByAgentId,
  } = useSelector((state: RootState) => state.inbox);
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const dispatch = useDispatch<AppDispatch>();
  const totalOpenConversationsByCategoryId = useSelector((state: RootState) =>
    getTotalOpenConversationByCategoryId(state)
  );
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const deleteConversationCategory = useMutation(
    async (categoryId: string) => {
      const { data } =
        await ConversationCategoriesService.deleteConversationCategory(
          categoryId
        );
      return data;
    },
    {
      onSuccess: (data) => {
        if (conversationCategoryId === data.id) {
          dispatch(
            changeActiveTab({
              tab: "all-conversations",
              conversationCategoryId: null,
            })
          );
        }
        queryClient.setQueryData(
          apiRoutes.listConversationCategoriesDetailed(),
          (oldData?: ConversationCategoryDetailed[]) => {
            return (
              oldData?.filter((category: any) => category.id !== data.id) || []
            );
          }
        );
        queryClient.setQueryData(
          apiRoutes.listConversationCategories(),
          (oldData?: ConversationCategory[]) => {
            return (
              oldData?.filter((category: any) => category.id !== data.id) || []
            );
          }
        );
        toast({
          title: "Categoria deletada",
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );
  useQuery(
    apiRoutes.listConversationCategoriesDetailed(),
    async () => {
      const { data } =
        await ConversationCategoriesService.listConversationCategoriesDetailed();
      return data;
    },
    {
      onSuccess: (data) => {
        dispatch(setConversationCategories(data));
      },
      refetchInterval: 1000 * 60 * 1, // redundant with the socket to guarantee the data is always up to date
    }
  );

  useQuery(
    apiRoutes.getConversationIdsWithOpenTickets({
      daysSinceLastMessage,
    }),
    async () => {
      const { data } =
        await ConversationsService.getConversationIdsWithOpenTickets({
          daysSinceLastMessage,
        });
      return data;
    },
    {
      onSuccess: (data) => {
        dispatch(setOpenConversationIds(data.map((c) => c.id)));

        const noAgentConversationIds = data
          .filter(({ agentId, id }) => !agentId && id)
          .map(({ id }) => id);
        if (noAgentConversationIds.length > 0) {
          dispatch(setHasNoAgentConversationIds(noAgentConversationIds));
        }

        const conversationsByAgentId = data.filter(({ agentId }) => agentId !== null) as { id: string; agentId: string; }[];
        dispatch(setConversationsIdsByAgentId(conversationsByAgentId));
      },
      refetchInterval: 1000 * 60 * 1, // redundant with the socket to guarantee the data is always up to date
    }
  );

  useQuery(
    apiRoutes.getConversationIdsWithUnreadMessages({
      daysSinceLastMessage,
    }),
    async () => {
      const { data } =
        await ConversationsService.getConversationIdsWithUnreadMessages({
          daysSinceLastMessage,
        });
      return data;
    },
    {
      onSuccess: (data) => {
        dispatch(setUnreadConverstionIds(data.map((c) => c.id)));
      },
      refetchInterval: 1000 * 60 * 1, // redundant with the socket to guarantee the data is always up to date
    }
  );

  useQuery(
    apiRoutes.listConversationSummary({
      daysSinceLastMessage,
    }),
    async () => {
      const { data } = await ConversationsService.listConversationSummary({
        daysSinceLastMessage,
      });
      return data;
    },
    {
      onSuccess: (data) => {
        dispatch(setConversationIdToCategoryIdMap(data));
      },
      refetchInterval: 1000 * 60 * 1, // redundant with the socket to guarantee the data is always up to date
    }
  );

  return (
    <div>
      <Sider
        topElement={
          <Flex
            justifyContent={"space-between"}
            alignItems="center"
            height="60px"
          >
            <Text fontSize="2xl" fontWeight="bold">
              Inbox
            </Text>
            <Flex alignItems="center">
              <IconButton
                size="sm"
                aria-label="Análises de Atendimentos"
                onClick={() => navigate(appPaths.reports.index())}
                icon={<FaChartBar fontSize={16} />}
              />
              <PopoverNewConversation />
              <IconButton
                size="sm"
                aria-label="Pesquisar"
                onClick={() => navigate(appPaths.customers.index())}
                icon={<FaSearch fontSize={16} />}
              />
            </Flex>
          </Flex>
        }
        width={width}
        activeTab={activeTab}
        items={[
          {
            rightElement: (
              <Text>{Object.keys(unreadConversationIds).length || ""}</Text>
            ),
            title: ConversationTabsEnum.NOT_READ,
            icon: <FaInbox />,
            onClick: () =>
              dispatch(
                changeActiveTab({
                  tab: "not-read",
                  conversationCategoryId: null,
                })
              ),
            children: [],
          },
          {
            rightElement: (
              <Text>{Object.keys(openConversationIds).length || ""}</Text>
            ),
            title: ConversationTabsEnum.ALL_CONVERSATIONS,
            icon: <BsPeopleFill />,
            onClick: () =>
              dispatch(
                changeActiveTab({
                  tab: "all-conversations",
                  conversationCategoryId: null,
                })
              ),
            children: [],
          },
          {
            rightElement: (
              <Text>{
                (conversationsIdsByAgentId[currentUser?.sub!] || []).length || ""
              }</Text>
            ),
            title: ConversationTabsEnum.MY_CONVERSATIONS,
            icon: <BsPersonCheckFill />,
            onClick: () =>
              dispatch(
                changeActiveTab({
                  tab: "agent-conversations",
                  conversationCategoryId: null,
                  agentId: currentUser?.sub,
                })
              ),
            children: [],
          },
          {
            rightElement: (
              <Text>{Object.keys(hasNoAgentConversationIds).length || ""}</Text>
            ),
            title: ConversationTabsEnum.NO_ASSIGNMENT,
            icon: <MdPersonOff />,
            onClick: () =>
              dispatch(
                changeActiveTab({
                  tab: "no-assignment",
                  conversationCategoryId: null,
                })
              ),
            children: [],
          },
          {
            rightElement: <PopoverNewConversationCategory />,
            title: "Categorias",
            icon: <BiSolidCategory />,
            onClick: () => {},
            children: (conversationCategories || [])
              .filter((category) => !!category.id)
              .map((category) => ({
                rightElement: (
                  <Text>
                    {totalOpenConversationsByCategoryId[category.id!] || ""}
                  </Text>
                ),
                title: category.name!,
                contextMenuItems: [
                  {
                    title: "Apagar",
                    onClick: () =>
                      deleteConversationCategory.mutate(category.id!),
                  },
                ],
                onClick: () =>
                  dispatch(
                    changeActiveTab({
                      tab: "conversation-category",
                      conversationCategoryId: category.id,
                    })
                  ),
              })),
          },
          {
            rightElement: <></>,
            title: "Colaboradores",
            icon: <BiSolidCategory />,
            onClick: () => {},
            children: (companyAgents || [])
              .filter((agent) => !!agent.id)
              .map((agent) => ({
                rightElement: (
                  <Text>
                    {(conversationsIdsByAgentId[agent.id!] || []).length || ""}
                  </Text>
                ),
                title: agent.name!,
                icon: <Avatar name={agent.name} size={"xs"} />,
                onClick: () =>
                  dispatch(
                    changeActiveTab({
                      tab: "agent-conversations",
                      conversationCategoryId: null,
                      agentId: agent.id,
                      agentName: agent.name,
                    })
                  ),
              })),
          },
        ]}
      />
    </div>
  );
};

export default SideNavigation;
