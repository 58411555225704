import { Box } from "@chakra-ui/react";
import React from "react";
import WhatsappMessageItem, {
  WhatsappMessageItemProps,
} from "../WhatsappMessageItem";

interface WhatsappTemplatePreviewProps extends WhatsappMessageItemProps {
  height?: string;
}

const WhatsappTemplatePreview = ({
  message,
  file,
  buttons,
  footer,
  fileUrl,
  height='100vh',
}: WhatsappTemplatePreviewProps) => {
  
  return (
    <Box
      height={height}
      minWidth="361px"
      backgroundImage={"/template-preview-bg.png"}
      padding={3}
      overflowX='visible'
      overflowY='scroll'
    >
      <WhatsappMessageItem
        message={message}
        file={file}
        buttons={buttons}
        footer={footer}
        fileUrl={fileUrl}
      />
    </Box>
  );
};

export default WhatsappTemplatePreview;
