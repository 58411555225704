import { Box, BoxProps } from "@chakra-ui/react";
import ReactECharts from "echarts-for-react";
import { ReactNode } from "react";

interface CustomEChartProps extends BoxProps {
  option: any;
  chartWidth?: string | number;
  chartHeight?: string | number;
  topRightComponent?: ReactNode;
}

const CustomECharts = ({
  option,
  chartHeight = "500px",
  chartWidth = "100%",
  topRightComponent,
  ...rest
}: CustomEChartProps) => {
  return (
    <Box position='relative' {...rest}>
      <ReactECharts option={option} style={{ width: chartWidth, height: chartHeight }} />
      {topRightComponent && (
        <div style={{ position: "absolute", top: "3px", right: "6px" }}>
          {topRightComponent}
        </div>
      )}
    </Box>
  );
};

export default CustomECharts;
